import {UserModel} from "./User";
import {OrderModel} from "./Order";

export interface PayoutModel {
  id: number;
  order_id : number;
  user_id : number;
  rate : number;
  work_time : number;
  amount : string;
  km_pay: string;
  transport_pay: string;
  agreed_wage: string;
  is_approve: number;
  is_paid: number;
  status : number;
  completed_at : string;
  approve_at : string;
  paid_at : string;
  created_at : string;
  updated_at : string;

  translator: UserModel;
  order: OrderModel

  // virtual fields
  total_rate: string;
  net_total: string;
}


export const PayoutStatus = {
  PENDING: '3',
  COMPLETE: '4'
}