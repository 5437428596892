import React  from 'react';
import {CustomerProps, UserDataSchema} from "./Model";
import Lang from "../../common/lang/Lang"
import {Icons, IState as CommonState, ShowRecords, ExportButton,
  ActionLink, CreateLink, EditLink, GeneratePermitLink,showConfirmBox,CONST, actionButton
} from "../../common";
import {CustomerBreadcrumb} from "./Data";
import {ListForm, ListHeader, ListingLayout} from "../../layout/Listing";
import {CustomerInfo} from "./CustomerInfo";
import {UserModel} from "../../model";
import _ from "lodash";
import {RouteComponentProps} from "react-router";
import API from "../../common/AxoisClient";
import {CustomerActions} from "./CustomerActions";
import {BlockTranslator} from "./popup/BlockTranslator";
import {SuperCustomer} from "./popup/SuperCustomer";
import {UserHelper} from "../../helpers/UserHelper";
import CustomerFilter from './filter/CustomerFilter';
import {list_key} from "../../helpers/OrderHelper";
import UserServices from '../../services/UserServices';
const moment = require("moment");


declare var window:any;




interface IState extends CommonState{
  UserData: Array<UserDataSchema>;
  
  ModalData?: UserModel;
  show?: boolean;
  contractPopup?:boolean;
  unfavuserlist?:Array<any>;
  supercontractPopup?:boolean;
  Companylist?:Array<{value: string, label:string}>;
  
}


export class Listing extends React.Component<CustomerProps, IState> {
  state: IState;
  records:React.RefObject<ShowRecords> = React.createRef();

 

  componentDidMount() {
   
    API.get("users/customers/company-list").then(res => {
    
    this.setState({
      
      Companylist: [{label: Lang.choose_company, value: ''}, ...res.data]
    })
  })
  this.setState({
    show: false
  })
}
 

  handleChangeStatus = (event : React.ChangeEvent<any>,id:number,action:number)=>{
     
       let actions:string = '';
       if(action === 1){
       actions = 'inactive'
      } else{
        actions = 'active'
       }
       showConfirmBox(Lang.customer_status_confirm, async () => {
      API.post('users/customers/bulk-action',{action:actions,ids:[id]}).then(res => {
       this.filterRecords();
      });
    })
     
  }

  showContent = (data: UserModel, index: number) => {
   let cols = [];
    cols.push(...[
      <td key={index}>{this.records.current.checkbox(data.id)}</td>,
      this.records.current.createdTd("id", ()=>{
          return (
              <div style={{width:'70px'}}>{( data.user_code !== null ) ? data.user_code : '--'}</div>
          )
      }),
      this.records.current.createdTd("company_id", () =>{
        return(
          <div>
          {
            UserHelper.getCompany(data)
          }
          { data.customer && data.customer.is_convert_to_public===1 && data.customer.other_company &&
            <span><br/><br/><strong>Billing Company</strong><br/>{data.customer.other_company.name}</span>
          }
          </div>
        )
      }),
      this.records.current.createdTd("department_id", ()=>{
        return (
          <div>
            {UserHelper.getDept(data)}
            { data.customer && data.customer.is_convert_to_public===1 && data.customer.other_dept &&
              <span><br/><br/><strong>Billing Department</strong><br/>{data.customer.other_dept.name}</span>
            }
          </div>
        )
      }),
      this.records.current.createdTd("department_unit_id", ()=>{
        return (
          <div>
          { data.customer && data.customer.customer_type === 1 &&
            UserHelper.getDeptUnit(data)
          }
          { data.customer && data.customer.is_convert_to_public===1 && data.customer.other_dept_unit &&
            <span><br/><br/><strong>Billing Department Unit</strong><br/>{data.customer.other_dept_unit.name}</span>
          }
          </div>
        )
      }),
      this.records.current.createdTd("name", () => {
        return (
            <div style={{width:'200px'}}>
          <GeneratePermitLink scope={'customers.index'} to={'#'} onClick={() => this.setState({ModalData: data, show: true})}>
            {data.name}
          </GeneratePermitLink>
            { data.customer && data.customer.customer_type === 1 &&
              <span><br/><br/><strong>Type: </strong>Public</span>
            }
            { data.customer && data.customer.customer_type === 2 &&
              <span><br/><br/><strong>Type: </strong>Private</span>
            }
            { data.customer && data.customer.customer_type === 1 && data.customer.ean_number &&
              <span><br/><br/><strong>{Lang.ean_number} </strong>{data.customer.ean_number}</span>
            }
            { data.customer && data.customer.customer_type === 1 && !data.customer.ean_number &&
              <span><br/><br/><strong>{Lang.ean_number} </strong>
                <i className="fa fa-exclamation-triangle" style={{color:"red",fontSize: "24px"}}></i>
              </span>
            }
            { data.customer && data.customer.cpr_number &&
              <span><br/><br/><strong>{Lang.cpr_number} </strong>{data.customer.cpr_number}</span>
            }
            { data.customer && data.email.split('@')[1]==='silkeborg.dk' &&
              <span><br/><br/><strong>{Lang.economics_ref_id}: </strong>{'26'}</span>
            }
            { (data.customer && data.email.split('@')[1]!=='silkeborg.dk' && data.customer.customer_type === 1 && (data.company?.is_special_company ?? 0) === 1 && data.ordercomplete && data.ordercomplete.department_unit) &&
              <span><br/><br/><strong>{Lang.economics_ref_id}: </strong>{data.ordercomplete.department_unit.economics_ref_id}</span>
            }
            { (data.customer && data.email.split('@')[1]!=='silkeborg.dk' && data.customer.customer_type === 1 && data.company?.is_special_company === 1 && data.customer.department_unit && (!data.ordercomplete || !data.ordercomplete.department_unit)) &&
              <span><br/><br/><strong>{Lang.economics_ref_id}: </strong>{data.customer.department_unit.economics_ref_id}</span>
            }
            { (data.customer && data.email.split('@')[1]!=='silkeborg.dk' && data.customer.customer_type === 1 && data.company?.is_special_company !== 1 && data.customer.department) &&
              <span><br/><br/><strong>{Lang.economics_ref_id}: </strong>{data.customer.department.economics_ref_id}</span>
            }
            { (data.customer && data.email.split('@')[1]!=='silkeborg.dk' && data.customer.customer_type === 1 && data.company?.is_special_company !== 1 && !data.customer.department) &&
              <span><br/><br/><strong>{Lang.economics_ref_id}: </strong>{data.company.economics_ref_id}</span>
            }
            { (data.customer && data.email.split('@')[1]!=='silkeborg.dk' && data.customer.customer_type == 2) && data.company &&
              <span><br/><br/><strong>{Lang.economics_ref_id}: </strong>{data.company.economics_ref_id}</span>
            }
            { data.company.is_special_company === 1 &&
              <span><br/><br/><span className={'badge badge-primary'}>{Lang.special}</span></span>
            }
            </div>
        )  
      }),
      this.records.current.createdTd("mobile_no", () => {
        return (
          <div style-={{width:'200px'}}>
            <span>{(data.country_code !== null ?'+' + data.country_code  +'-': '')+ data.mobile_no}</span> <br/>
            {UserHelper.getContactPersonContact(data)!='-' &&
            <span><b>{Lang.contact_phone}</b> {UserHelper.getContactPersonContact(data)}</span>
            }

            {data.customer && data.customer.order_contact_phone &&
              <span><br/><br/><b>{Lang.order_contact_number}</b> {(data.country_code !== null ?'+' + data.country_code  +'-': '')+ data.customer.order_contact_phone}</span>
            }
          </div>
        )
      }),
      this.records.current.createdTd("email", data.email),

      this.records.current.createdTd("is_super_customer", () => {
        if(data.customer && data.customer.is_super_customer === 1){
          return <span className={'badge badge-primary'}>{Lang.yes}</span>
        }
        if(data.customer && data.customer.is_super_customer !== 1){
          return <span className={'badge badge-warning'}>{Lang.no}</span>
        }
      }),
     
      this.records.current.createdTd("customer_info", () =>  {
        return (  <div style={{width:'200px'}}>
         <span ><b>{Lang.created_at}</b> : {moment(data.created_at).format(CONST.date_format)}</span><br/>
          <span ><b>{Lang.created_by}</b> :
          { data.created_by_user &&
            data.created_by_user.name
          } 
          { !data.created_by_user  && data.registration_from !== null &&
            data.registration_from
          }

       
     </span><br/>
     <span ><b>{Lang.last_login}</b> : {data.last_login}</span><br/>
         <span ><b>{Lang.is_online}</b> :
        
         { data.is_online === 1 && 
           <span className={'badge badge-primary'}>{Lang.yes}</span>
         }
         { data.is_online === 0 && 
           <span className={'badge badge-warning'}>{Lang.no}</span>
         }
         
     </span><br/>
     
     <span><b>{Lang.last_login_platform} : </b> {data.last_login_platform} </span> <br/>

     <span ><b>{Lang.account_status}</b> : 
     { data.status === 1 &&
        <span className={'badge badge-primary'}>{Lang.active}</span>
     }
     { data.status === 2 &&
        <span className={'badge badge-warning'}>{Lang.inactive}</span>
     }
       
     </span><br/><br/>
     

    
     { data.status === 1 && 
   <input type ="button" className={'btn btn-sm btn-warning'} onClick={(e) =>this.handleChangeStatus(e, data.id,data.status)}     value={Lang.deactivate}   />
      }
      { data.status === 2 && 
   <input type ="button" className={'btn btn-sm btn-primary'} onClick={(e) =>this.handleChangeStatus(e, data.id,data.status)}     value={Lang.active}   />
      }
 

   </div>
        )
      }),
      
    
      this.records.current.createdTd("action", () =>  {
        const { sendConfirmationEmail, sendPasswordReset, verifyEmail, unblockLogin } = new UserServices(data)
        return (
          <div>
            <EditLink scope={'customers.update'} to={`/users/customers/info/${data.id}`}   title={Lang.edit_customer} />
            <ActionLink scope={'orders.store'} to={`/users/customers/${data.id}/orders/create`}  title={Lang.create_order} >
              <i className={Icons.order} />
            </ActionLink>
            <CustomerActions
              customer_data={data.id}
              SuperCustomer_data={data.company.is_special_company}
              showTPop={() => {
                this.setState({contractPopup: true,ModalData: data});  
              }}
              showSPop={() => {
                this.setState({supercontractPopup: true,ModalData: data});  
              }}
              sendConfirmationEmail={sendConfirmationEmail}
              sendPasswordReset={sendPasswordReset}
              verifyEmail={verifyEmail}
              unblockLogin={unblockLogin}
              />
            
          </div>
        )
      })
    ]);
    cols = _.compact(cols);

    return (
      <tr key={list_key(data.id)}>{cols}</tr>
    );
  }

  filterRecords = (data?: any) => {
    if( this.records && this.records.current !== null){
      if(data !== undefined){
        this.records.current.setFormFields(data);
      }else {
        this.records.current.setFormFields();
      }
    }
  }

 

  render() {
    const status = [{'label': "Select Status", "value": ""}, {'label': "Active", "value": 1}, {'label': "Pending", "value": 2}];
    const customer_type = [{'label': "Select Type", "value": ""}, {'label': "Public", "value": 1}, {'label': "Private", "value": 2}];
    return (
      <ListingLayout breadcrumb={CustomerBreadcrumb}>
          <ListHeader title={'customers'} icon={Icons.users} >
            <ExportButton records={this.records} fileName={Lang.exportCustomers}/>
            &nbsp;
            <CreateLink label={Lang.add_customer} to={`/users/customers/create`} scope={'customers.store'} />
          </ListHeader>

          <ListForm
            form={(
              <CustomerFilter status={status} customer_type={customer_type} Companylist={this.state !== null ? this.state.Companylist: []} getResults={this.filterRecords} />
            )}
            table={(
              <ShowRecords
                url={"users/customers"}
                showRecords={this.showContent}
                ref={this.records}
                isNeedAction={true}
                actionButtons={actionButton({records: this.records, 'url': "users/customers/bulk-action", "module": 'customers'})}
                callBack={() => {
                  window.KTApp.initTooltips();
                }}
              />
            )}
          />
         {
            this.state !== null &&
            <CustomerInfo
              show={this.state.show}
              onHide={() => this.setState({show: false})}
              userinfo={this.state.ModalData}
            />
         }
        {
          
        this.state !== null &&
         this.state.contractPopup &&
                <BlockTranslator
                    show={this.state.contractPopup}
                    onHide={() => this.setState({contractPopup: false})}
                    transInfo={this.state.ModalData}
                   />
                  
        }
        { 
         this.state !== null &&
        this.state.supercontractPopup &&
          <SuperCustomer
            show={this.state.supercontractPopup}
            onHide={() => this.setState({supercontractPopup: false})}
            transInfo={this.state.ModalData}
            />
        }
        </ListingLayout>

    )
  }
}