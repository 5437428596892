import API from "../common/AxoisClient";
import {Subject} from "rxjs/index";
import {LoaderType} from "../common/Loader";
import {showConfirmBox, SwitchLoader} from "../common";
import Lang from "../common/lang/Lang";


// send mail to customer popup service
const subject = new Subject();
export const SeePayoutOrderService = {
  showPopup: (data: number, type: string) => {
    PayoutServices.getPendingPayOrders(data).then((res) => {
      subject.next( {show: true, translator_id: data, orders: res, type: type } )
    });
  },
  showCompletePopup: (data: number, type: string) => {
    PayoutServices.getCompletePayOrders(data, false).then((res) => {
      subject.next( {show: true, translator_id: data, orders: res, type: type } )
    });
  },
  hidePopup: () => subject.next({show: false, translator_id: [] }),
  getPopupState: () => subject.asObservable()
};

export class PayoutServices {

  /**
   * get list of pending payout orders of translator
   * @param trans_id
   * @returns {Promise<T>}
   */
  public static async getPendingPayOrders(trans_id) {
    const res = await API.get('payouts/get-orders', {params: {trans_id: trans_id, 'type': 'pending'}});
    return res.data;
  }

  /**
   * get list of approved orders to pay of translator
   *
   * @param trans_id
   * @param type
   * @returns {Promise<T>}
   */
  public static async getCompletePayOrders(trans_id, type?) {
    const res = await API.get('payouts/get-orders', {params: {trans_id: trans_id, 'type': 'complete', 'is_paid' : type}});
    return res.data;
  }

  /**
   * update inline update fields
   *
   * @param formData
   * @returns {Promise<T>}
   */
  public static async updateFields(formData) {
    const res = await API.post('payouts/update-fields', formData);
    return res.data;
  }

  /**
   * set pending orders to complete for pay
   *
   * @param orders
   * @returns {Promise<void>}
   */
  public static async approveOrders(orders) {
    return showConfirmBox(Lang.approve_confirm, async () => {
      SwitchLoader(LoaderType.BUTTON);
      const res = await API.post('payouts/approve-orders', {translator_id: orders, action: 3});
      return res.data;
    })
  }

  /**
   * approve all orders of translator
   * @param orders
   * @returns {Promise<void>}
   */
  public static async setApproveAllOrders(orders) {
    return showConfirmBox(Lang.approve_all_confirm, async () => {
      SwitchLoader(LoaderType.BUTTON);
      const res = await API.post('payouts/approve-orders', {translator_id: orders, action: 2});
      return res.data;
    })
  }

  /**
   * move approved orders into pending
   *
   * @param orders
   * @returns {Promise<void>}
   */
  public static async movePending(orders) {
    return showConfirmBox(Lang.back_to_confirm, async () => {
      SwitchLoader(LoaderType.BUTTON);
      const res = await API.post('payouts/approve-orders', {translator_id: orders, action: 3, action_type: 'rollback', 'type' : 'completed'});
      return res.data;
    });
  }

  /**
   * set complete orders to paid.
   *
   * @param orders
   * @returns {Promise<void>}
   */
  public static async setComplete(orders) {
    return showConfirmBox(Lang.paid_confirm, async () => {
      SwitchLoader(LoaderType.BUTTON);
      const res = await API.post('payouts/approve-orders', {translator_id: orders, action: 3, action_type: 'paid', 'type' : 'completed'});
      return res.data;
    });

  }

  /**
   * rollback paid orders into complete orders
   *
   * @param order_id
   * @returns {Promise<void>}
   */
  public static async  movePaidToComplete(order_id) {
    return showConfirmBox(Lang.back_paid_confirm, async () => {
      SwitchLoader(LoaderType.BUTTON);
      const res = await API.post('payouts/approve-orders', {translator_id: order_id, action: 4});
      return res.data;
    });
  }
}
