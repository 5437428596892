import {QuizType} from "../../model";


export const QuizValidations = function () {

  $(document).ready(function () {

    $( "#quiz_form" ).validate({
      // define validation rules
      rules: {
        title: {
          required: true,
          maxlength: 255
        },
        description: {
          required: true
        },
        we_learn_id: {
          required: function () {
            return $("#hidden_quiz_type").val() == QuizType.WE_LEARN;
          }
        },
        total_marks: {
          required: true,
          number: true
        },
        min_marks: {
          required: true,
          number: true
        },
        time: {
          number: true
        }
      }
    });
  });
}