import * as React from "react";
import {TableHeaderSchema} from "./Model";
import {getQueryStringParams} from "../Common";
import _ from "lodash";

let items: string;

const createHeader = (onSort: any, item: TableHeaderSchema, index: number) => {

  function Sort(name: string, type: string) {
    items = name;
    onSort(name, type)
  }
  if(item.show !== true)
    return null;

  let style = {};
  if(item.width !== undefined){
    style = {
      "width": `${item.width}%`
    }
  }

  if(item.sort === true){
    let items = getQueryStringParams("sort");
    return (<th key={index} className={'kt-datatable__cell kt-datatable__cell--sort kt-datatable__cell--sorted'} style={style} >
      <a  href={"/"}  onClick={(e) => {e.preventDefault(); Sort(item.name, item.type)}} >
        { item.label} &nbsp; &nbsp;
        {item.type === "asc" && items === item.name &&  <i className={'flaticon2-arrow-down'}/>}
        {item.type === "desc" && items === item.name &&  <i className={'flaticon2-arrow-up'}/>}
      </a>
      </th>)
  } else {
    return (<th key={index}  style={style}>{ item.label}</th>)
  }
}


export const TableHeader = (props: any) => {

  let header = [];
  if( props.showCheckbox === true){
    header.push(<th key={10000}><label className="kt-checkbox kt-checkbox--single kt-checkbox--solid">
      <input type="checkbox" onClick={props.checkAll} />&nbsp;<span/></label>
    </th>);
  }
  props.columns.map((item: TableHeaderSchema, index: number) => {
    return header.push(...[
      createHeader(props.onSort, item, index)
    ])
  });
  header = _.compact(header);
  return(
    <tr className={'kt-datatable__row'}>{header}</tr>
  );
}
