import * as React from 'react';
import API from "../../common/AxoisClient";
import { Editor } from '@tinymce/tinymce-react';
import {showNotification, LoaderType, Breadcrumb, SwitchLoader,SubmitButton} from "../../common";
import Lang from "../../common/lang/Lang";
import {RouteComponentProps} from "react-router";
import {EditBreadcrumb} from "./Data";
import {SmsTemplateValidations} from "./Validations";
import * as _ from "lodash";
declare const window: any;

export interface ETemplateState {
    id : string;
    name : string;
    type : string;
    content : string;
    keyword : string;
    typeList : Array<any>;
    keywordList : any;
    keywordArr : Array<any>;
}

export class EditSmsTemplates extends React.Component<RouteComponentProps,ETemplateState> {
    state: ETemplateState;
    constructor(props: RouteComponentProps) {
        super(props);
        this.state = {
            id:'',
            name:'',
            type:'',
            content:'',
            keyword:'',
            typeList: [],
            keywordList: {},
            keywordArr: [],
        }
        SmsTemplateValidations();
        this.handleEditorChange = this.handleEditorChange.bind(this);
        this.smsTempUpdate = this.smsTempUpdate.bind(this);

        let tinymce: any;
    }

    handleEditorChange(content : any, editor : any) {
        if(content !== ''){
            $( '.tox-tinymce' ).css( 'border-color', '#ccc' );
            $("#errorEditor").html(" ");
            this.setState({ content });
        } else {
            $( '.tox-tinymce' ).css( 'border-color', '#fd397a' );
            $("#errorEditor").html("Dette felt skal udfyldes.");
            this.setState({ content });
        }
    }

    handleCheckboxChange = (event : React.ChangeEvent<any>) => {
        const targetCheck = event.target;
        const checkValue = targetCheck.value;
        if(event.target.checked === true) {
          window.tinymce.activeEditor.execCommand('mceInsertContent', false, checkValue);
            this.setState((prevState:any) => ({
                keywordArr: [...prevState.keywordArr, checkValue],
               // content : prevState.content+' '+ checkValue+''
            }) );
        } else {
            this.setState({
                keywordArr: this.state.keywordArr.filter(function(item: number) {
                    return item !== checkValue
                })
            });
        }
    }

    onChange = (event : React.ChangeEvent<any>) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.setState({
            [name]: value
        } as any);
    }

    handleTypeChange = (event : React.ChangeEvent<any>) => {
        const typeValues = event.target;
        const value = typeValues.value;
        let allKeywords:Array<any> = []
        if(value !=='') {
            allKeywords = this.getKeyWordsList(this.state.typeList,value);
        }

        this.setState({
            keywordList: allKeywords,
            type:value
        });
    }

    getKeyWordsList = (typeListData:any, value:string) => {
        let allKeywords:Array<any>;
        if(_.isEmpty(typeListData)){
            typeListData = this.state.typeList
        }
        allKeywords = [];
        if(value !=='') {
            let parentKey:any = typeListData[value];
            if(typeof (parentKey[0]['parent_key']) !== 'undefined' && parentKey[0]['parent_key'] !=''){
                let parentData:Array<any> = typeListData[parentKey[0]['parent_key']];
                let objData:Array<any> = typeListData[value];
                //allKeywords = _.uniqBy([...parentData, ...objData],'db_name');

                allKeywords['Parent'] = parentData;
                allKeywords['Child'] = objData;
            } else {
                allKeywords['Parent'] = typeListData[value];
            }
        }
        return allKeywords;
    }

    componentDidMount() {
        API.get("smstemplates/get-sms-type").then(res => {
            let keywordListData:Array<any> = []
            if(this.state && res.data[this.state.type]){
                keywordListData = this.getKeyWordsList(res.data,this.state.type);
            }
            this.setState({
                typeList: res.data,
                keywordList: keywordListData,
            });
        })
    }

    componentWillMount(){
        var url = window.location.pathname;
        var id = url.substring(url.lastIndexOf('/') + 1);
        API.get("smstemplates/get-sms-data",{params:{'id':id}}).then(response => {


            let keywordListData:Array<any> = []
            if(response.data.type && this.state.typeList[response.data.type]){
                keywordListData = this.state.typeList[response.data.type];
            }

            this.setState((prevState:any) => ({
                id:id,
                name:response.data.name,
                type:response.data.type,
                content:response.data.content,
                keywordArr: response.data.keywords.split(","),
                keywordList: keywordListData
            }) );
        })
    }

    smsTempUpdate(event : any) {
        event.preventDefault();
        SwitchLoader(LoaderType.BUTTON);
        if($("#create_smsTemplate").valid() || this.state.content === '') {
            if(this.state.content === ''){
                $( '.tox-tinymce' ).css( 'border-color', '#fd397a' );
                $("#errorEditor").html("Dette felt skal udfyldes.");
                return false;
            }

            API.post("smstemplates/sms-temp-update", this.state).then(response => {
                showNotification("success", Lang.sms_temp_updated);
                this.props.history.push(`/settings/sms-templates`);
            })
        }
    }

    render() {
        return (
            <div>
                <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                    <Breadcrumb data={EditBreadcrumb}/>
                    <form className="kt-form" onSubmit={this.smsTempUpdate} id={'create_smsTemplate'}>
                        <div className="col-md-6" style={{float:'left'}}>
                            <div className="kt-portlet">
                                <div className="kt-portlet__head">
                                    <div className="kt-portlet__head-label">
                                        <h3 className="kt-portlet__head-title">
                                            {Lang.edit_sms_template}
                                        </h3>
                                    </div>
                                </div>
                                <div className="kt-portlet__body">
                                    <div className="form-group">
                                        <label>Name</label>
                                        <input type="text" className="form-control" id="name" name="name"
                                               value={this.state.name} onChange={this.onChange} />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="exampleSelect1">Type</label>
                                        <select className="form-control" value={this.state.type} onChange={this.handleTypeChange}  id="type" name="type" >
                                            <option value="">Select</option>
                                            {  !_.isEmpty( this.state.typeList ) &&
                                            _.keys(this.state.typeList).map((item: string) => {
                                                return   <option>{item}</option>
                                            })
                                            }
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label>Content</label>
                                        <Editor apiKey="aowvzx5np520a0j8uimtcb8yax0qv017e88t0p5j5xmq8t27" id='insertText' init={{
                                            menubar:false, selector: 'textarea', branding: false, forced_root_block : false, plugins: ['code', 'table'],
                                            toolbar: "undo redo | alignleft aligncenter alignright | bold italic | link |  fontsizeselect  | bullist | numlist | table|code",
                                            height:600,entity_encoding : "raw"
                                        }} value={this.state.content} onEditorChange={this.handleEditorChange} />
                                        <span id='errorEditor' style={{color:'#fd397a'}}/>
                                    </div>
                                </div>
                            </div>

                            <div className="kt-portlet__foot">
                                <div className="kt-form__actions">
                                 <SubmitButton className="btn btn-primary" label={Lang.submit}/>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6" style={{float:'left'}}>
                            <div className="kt-portlet">
                                <div className="kt-portlet__body">
                                    <br/>
                                    {this.state.keywordList.Parent &&
                                    this.state.keywordList.Parent.length > 0 &&
                                    <label>Keywords For { this.state.keywordList.Parent[0].key_word_type }</label>
                                    }
                                    <br/>
                                    <div className={'row'}>
                                        {
                                            this.state.keywordList.Parent &&
                                            this.state.keywordList.Parent.length > 0 &&
                                            this.state.keywordList.Parent.map((value:any, key:number)=> {
                                                return <div className={'col-md-6'}><div className="form-group"><label><input  key={key} type="checkbox" id="keyword"  onChange={(e:any)=>this.handleCheckboxChange(e)} checked={this.state.keywordArr.indexOf('{{'+_.toUpper(value.db_name)+'}}')>-1} name="keyword" value={'{{'+_.toUpper(value.db_name)+'}}'} />&nbsp;&nbsp;{value.display_name} </label></div></div>
                                            })
                                        }
                                    </div>
                                    <br/>
                                    {this.state.keywordList.Child &&
                                    this.state.keywordList.Child.length > 0 &&
                                    <label>Keywords For { this.state.keywordList.Child[0].key_word_type }</label>
                                    }
                                    <br/>
                                    <div className={'row'}>
                                        {
                                            this.state.keywordList.Child &&
                                            this.state.keywordList.Child.length > 0 &&
                                            this.state.keywordList.Child.map((value:any, key:number)=> {
                                                return <div className={'col-md-6'}><div className="form-group"><label><input  key={key} type="checkbox" id="keyword"  onChange={(e:any)=>this.handleCheckboxChange(e)} checked={this.state.keywordArr.indexOf('{{'+_.toUpper(value.db_name)+'}}')>-1} name="keyword" value={'{{'+_.toUpper(value.db_name)+'}}'} />&nbsp;&nbsp;{value.display_name} </label></div></div>
                                            })
                                        }
                                    </div>
                            </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}
