// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".InfoPill_info_pill__1ZDka {\n  width: -moz-fit-content;\n  width: fit-content;\n  padding: 2px 8px;\n  border-radius: 5px;\n  font-weight: 500;\n  margin-right: 5px;\n}\n.InfoPill_info_pill__1ZDka:nth-last-child(1) {\n  margin-right: 0;\n}", ""]);
// Exports
exports.locals = {
	"info_pill": "InfoPill_info_pill__1ZDka"
};
module.exports = exports;
