import React, {useState, useEffect} from 'react';
import {QuestionType, QuestionTypeTitle, QuizQuestionModel, QuizQuestionOptionModel} from "../../../model";
import {Icons, RequestWaiting} from "../../../common";
import Lang from "../../../common/lang/Lang";
import {QuestionListService, QuestionPopupService, QuestionService} from "../../../services";
import {QuestionPopup} from "../questions/QuestionPopup";
import _ from 'lodash';

export function Questions (props: {id: number, title: string}){

  const {id, title} = props;

  const [questions, setQuestion] = useState([] as Array<QuizQuestionModel>);

  useEffect(() => {
    QuestionService.getQuestions(id).then(res => {
      setQuestion(res)
    });
  },[]);


  QuestionListService.questions().subscribe((res: any) => {
    if(res.type === 'add'){
      setQuestion([res.data, ...questions])
    } else {
      const index = _.findIndex(questions, function(o) { return o.id === res.data.id; });
      questions[index] = res.data;
      setQuestion(questions)
    }
  });

  function removeQues(id) {
    QuestionService.deleteQuestion(id).then(res => {
      if(res !== undefined){
        let newq = questions.filter(it => {
          return it.id !== id;
        });
        setQuestion(newq)
      }
    });
  }

  return(
    <div className="row">
      <div className="col-xl-12">
        <div className="kt-portlet kt-portlet--height-fluid">
          <div className="kt-portlet__head">
            <div className="kt-portlet__head-label">
              <h3 className="kt-portlet__head-title">
                {props.title}
              </h3>
            </div>
            <div className="kt-portlet__head-toolbar">
              <ul className="nav nav-pills nav-pills-sm nav-pills-label nav-pills-bold" role="tablist">
                <li className="nav-item">
                  <a className="nav-link active" data-toggle="tab" href="#kt_widget5_tab1_content" onClick={
                    () => QuestionPopupService.addQuestion(id)
                  } role="tab">
                    {Lang.create}
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="kt-portlet__body">
            <div className="tab-content">
              <div className="tab-pane active" id="kt_widget5_tab1_content" aria-expanded="true">
                <div className="kt-widget5">
                  <RequestWaiting item={questions}>
                    { questions.length > 0 &&  questions.map((item: QuizQuestionModel, index: number) => {
                        return (
                          <div className="kt-widget5__item"   key={item.id}>
                            <div className="kt-widget5__content">
                              <div className="kt-widget5__section">
                                <a className="kt-widget5__title">
                                  <p dangerouslySetInnerHTML={{__html: item.question}}/>
                                </a>
                                <div className="kt-widget5__info">
                                  <span className="kt-badge kt-badge--info kt-badge--inline kt-badge--pill kt-badge--rounded">{QuestionTypeTitle[item.question_type]}</span>
                                </div>
                                {
                                  item.question_type !== QuestionType.SUBJECTIVE &&
                                    <div className="kt-widget5__info">
                                      {
                                        item.options.map((opt:QuizQuestionOptionModel, ind:number) => {
                                          return (
                                            <React.Fragment key={opt.id}>
                                              <span>Options {ind+1}: </span>
                                              <span className="kt-font-info">{opt.options}</span>
                                            </React.Fragment>
                                          )
                                        })
                                      }
                                    </div>
                                }
                              </div>
                            </div>
                            <div className="kt-widget5__content">
                              <div className="kt-widget5__stats">
                                <span className="kt-widget5__number">{item.total_marks}</span>
                                <span className="kt-widget5__sales">Marks</span>
                              </div>
                              <div className="kt-widget5__stats">
                                <span className="kt-widget5__number">
                                  <button type={'button'} className={'btn btn-warning btn-icon'}
                                  onClick={() => {
                                    QuestionPopupService.editQuestion(id, item);
                                  }}
                                  ><i className={Icons.edit}/></button>
                                </span>
                                <button type={'button'} className={'btn btn-danger btn-icon'}
                                        onClick={() => removeQues(item.id)}
                                ><i className={Icons.delete}/></button>
                              </div>
                            </div>
                          </div>
                        )
                      })
                    }
                  </RequestWaiting>
                </div>
              </div>
            </div>
          </div>
        </div>
        <QuestionPopup/>
      </div>
    </div>
  )
}