// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".TravelDistance_container__QlUod {\n  display: flex;\n  flex-direction: column;\n  width: 250px;\n}\n\n.TravelDistance_vertical_container__1LRhZ {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n.TravelDistance_horizontal_container__3Q0uG {\n  display: flex;\n  align-items: center;\n}\n\n.TravelDistance_icon__6uTMQ {\n  font-size: 1rem;\n  color: #74788d;\n  margin-right: 2px;\n}\n\n.TravelDistance_line__1YXVC {\n  width: 1px;\n  height: 50px;\n  border: 2px dashed #74788d;\n  margin: 5px 0;\n}\n\n.TravelDistance_distance_info__3bBZ1 {\n  margin-left: 10px;\n  color: black;\n  font-weight: 400;\n}\n\n.TravelDistance_bold__2MNLy {\n  font-weight: 400;\n  color: black;\n}", ""]);
// Exports
exports.locals = {
	"container": "TravelDistance_container__QlUod",
	"vertical_container": "TravelDistance_vertical_container__1LRhZ",
	"horizontal_container": "TravelDistance_horizontal_container__3Q0uG",
	"icon": "TravelDistance_icon__6uTMQ",
	"line": "TravelDistance_line__1YXVC",
	"distance_info": "TravelDistance_distance_info__3bBZ1",
	"bold": "TravelDistance_bold__2MNLy"
};
module.exports = exports;
