
declare var window: any;

export const RoleValidations = function () {

  $(document).ready(function () {

    $( "#create_role" ).validate({
      // define validation rules
      rules: {
        name: {
          required: true
        },
      },
      invalidHandler: function(event, validator) {
        var alert = $('#kt_form_1_msg');
        alert.removeClass('kt--hide').show();
      },
    });
  });
}