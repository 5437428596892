import * as React from 'react';
import API from "../../common/AxoisClient";
import {SearchInput, SearchSelect} from "../../common/form/SearchInput";
import {IState as CommonState} from "../../common/State";
import {ShowRecords} from "../../common/ShowRecords";
import {CONST, ExportButton, getQueryStringParams} from "../../common/Common";
import {Icons} from "../../common/Icon";
import {actionButton} from "../../common/table/BlukActionButtonsEmailSms";
import {RouteComponentProps} from "react-router";
import {AdminNotificationModel} from "../../model/AdminNotification";
import {ListBreadcrumb} from './Data';
import {ActionLink, CreateLink, EditLink, GeneratePermitLink} from "../../common/page/CreateButton";
import {ListForm, ListHeader, ListingLayout} from "../../layout/Listing";
import {Link} from "react-router-dom";
import {default as Lang} from "../../common/lang/Lang";
import NotificationFilter from './filter/NotificationFilter';
import _ from "lodash";
import {list_key} from "../../helpers/OrderHelper";


interface IState extends CommonState{
  UserData: Array<AdminNotificationModel>;
  
}


export class NotificationListing extends React.Component<RouteComponentProps, IState> {
  state: IState;
  records:React.RefObject<ShowRecords> = React.createRef();

  shouldComponentUpdate(nextProps: Readonly<RouteComponentProps>, nextState: Readonly<IState>, nextContext: any): boolean {
      
    return  this.state !== nextState;
     
 }

  notificationupdate =(id)=>{
   
    API.get(`notification-update/${id}`).then(res => {
     
    })
  
  }

  filterRecords = (data?: any) => {
    if( this.records && this.records.current !== null){
      if(data !== undefined){
        this.records.current.setFormFields(data);
      }else {
        this.records.current.setFormFields();
      }
    }
  }

   

    showContent = (data: AdminNotificationModel, index: number) => {
      let message:any = $.parseJSON(data.data)
      let cols = [];
        cols.push(...[
             /*<td key={index}>{this.records.current.checkbox(data.id)}</td>,*/
            this.records.current.createdTd("id", index),

            this.records.current.createdTd("title", () =>{return (message.title !== null) ? message.title : '--'; }),
           
              this.records.current.createdTd("title", () =>{
                return (
                  <div>
                <Link onClick={() => this.notificationupdate(data.id)}  to={`/${message.url}`} >  {(message.message !== null) ? message.message : '--' } </Link>
                </div>
                )
              }),
      this.records.current.createdTd("action", () => {
                    return (
                        <div>
                            <EditLink scope={'common-setting.update'} to={`/settings/common-setting/edit/${data.id}`} data-skin={'dark'}  title={Lang.edit_setting} />

                        </div>
                    )
                })

        ]);
        cols = _.compact(cols);

        return (
            <tr key={list_key(data.id)}>{cols}</tr>
        );
    
  }


  render() {

    return (
        <ListingLayout breadcrumb={ListBreadcrumb}>
          <ListHeader title={'admin_notification'} icon={Icons.bell} >
           
          </ListHeader>

          <ListForm
            form={(
              <NotificationFilter getResults={this.filterRecords} />
          )}

            table={(

              <ShowRecords
                  url={"settings/admin-notification"}
                  showRecords={this.showContent}
                  showCheckbox={false}
                  ref={this.records}
                isNeedAction={true}
                  actionButtons={actionButton({records: this.records, 'url': "settings/admin-notification/bulk-action"})}
              />
          )}
          />
        </ListingLayout>
    )
  }
}