import {CountryModel} from "./Country";

export interface LanguageModel {
  id: number;
  language_name: string;
 country_id: number;
 status: number;
 

  countries?: CountryModel;
  country?:CountryModel;
}

export const LanguageValues = (data?: LanguageModel) => {
  return {
    country_id: data !== undefined ? data.country_id : '',
    language_name: data !== undefined ? data.language_name : '',
   
  }
}