import React, {useEffect, useState, ReactNode} from 'react';
import {RequestService} from "../../services/RequestService";
import {PleaseWait} from "./PleaseWait";
import {NoRecords} from "../Common";
import _ from 'lodash';
import {SetWaitingArea} from "../AxoisClient";

interface RequestWaitInterface extends React.HTMLAttributes<HTMLElement>{
  item: any;
  from?: string;
}


export function RequestWaiting(props: RequestWaitInterface) {

  const [loader, setLoader] = useState(false);

  useEffect(() => {
    SetWaitingArea(props.from);
    RequestService.getRequestStatus().subscribe((msg: {from: string, start: boolean}) => {
      if(props.from !== undefined && props.from == msg.from){
        setLoader(msg.start);
      }
      else if(props.from === undefined){
        setLoader(msg.start);
      } else {
        setLoader(false);
      }
    })
  });

  if(loader){
    return <PleaseWait/>
  }

  const {item} = props;

  if(_.isArray(item)){
    if(!loader && item.length === 0){
      return <NoRecords/>
    }
  } else {
    if(!loader && !props){
      return <NoRecords/>
    }
  }


  return (
    <React.Fragment>
      {props.children}
    </React.Fragment>
  )
}