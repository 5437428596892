import Lang from "../../common/lang/Lang";

export const urls = {
  home: "/permission/",
  list: "/permission/role",
}

export const ListBreadcrumb = [
 
  {
    label: Lang.role,
    url: "#"
  }
];

export const CreateBreadcrumb = [
  {
    label: Lang.permission,
    url: urls.home
  },
  {
    label: Lang.role,
    url: urls.list
  },
  {
    label: Lang.create,
    url: '#'
  }
];

export const EditBreadcrumb = [
  {
    label: Lang.permission,
    url: urls.home
  },
  {
    label: Lang.role,
    url: urls.list
  },
  {
    label: Lang.edit,
    url: '#'
  }
];