import Lang from "../../common/lang/Lang";
import {RoleModel} from "../../model/Role";
import {QuizModel, WeLearnModel} from "../../model";

export const urls = {
  users: "/learn/",
  list: "/learn/quiz",
}

export const ListBreadcrumb = [
  {
    label: Lang.learn,
    url: urls.users
  },
  {
    label: Lang.quiz,
    url: "#"
  }
];

export const CreateBreadcrumb = [
  {
    label: Lang.learn,
    url: urls.users
  },
  {
    label: Lang.quiz,
    url: urls.list
  },
  {
    label: Lang.create,
    url: '#'
  }
];

export const EditBreadcrumb = [
  {
    label: Lang.learn,
    url: urls.users
  },
  {
    label: Lang.quiz,
    url: urls.list
  },
  {
    label: Lang.edit,
    url: '#'
  }
];


export interface IState{
  form: QuizModel;
  id?: number;
}