import * as React from 'react';
import {Link} from "react-router-dom";

export interface BrSchema {
  label: string;
  url: string;
}


interface IProps{
  data: Array<BrSchema>
}


export class Breadcrumb extends React.Component<IProps> {

  render() {
    return (
      <div className="kt-subheader   kt-grid__item" id="kt_subheader">
        <div className="kt-container  kt-container--fluid ">
          <div className="kt-subheader__main">
            <h3 className="kt-subheader__title">Dashboard</h3>
            <span className="kt-subheader__separator kt-hidden"/>
            <div className="kt-subheader__breadcrumbs">
              {this.props.data.map((item: BrSchema, index) => {
                return (
                    <span className="kt-subheader__group" key={index}>
                      <a href="#" className="kt-subheader__breadcrumbs-home"><i className="flaticon2-shelter"/></a>
                      <span className="kt-subheader__breadcrumbs-separator"/>
                      <Link to={item.url} className="kt-subheader__breadcrumbs-link"> {item.label}</Link>
                    </span>
                )
              })}

          </div>
        </div>
      </div>
  </div>
    )
  }
}