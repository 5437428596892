// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Stars_container__3sCUu {\n  background-color: rgba(0, 0, 0, 0.031372549);\n  padding: 3px 6px;\n  border-radius: 5px;\n}\n.Stars_container__3sCUu i {\n  margin: 0 1px;\n  color: #FFD400;\n}\n.Stars_container__3sCUu span {\n  margin-left: 2px;\n  color: #4D5373;\n}\n\n.Stars_stars_container__1zDGl {\n  display: flex;\n  flex-direction: column;\n}\n\n.Stars_ratings_number__172Rm {\n  color: black;\n  display: flex;\n  justify-content: center;\n  font-size: 0.8rem;\n  position: relative;\n  top: -3px;\n  margin-bottom: -5px;\n}", ""]);
// Exports
exports.locals = {
	"container": "Stars_container__3sCUu",
	"stars_container": "Stars_stars_container__1zDGl",
	"ratings_number": "Stars_ratings_number__172Rm"
};
module.exports = exports;
