import React from 'react';

export function ErrorDiv(msg: string) {

  return (
    <div className="alert alert-outline-warning fade show" role="alert">
      <div className="alert-icon"><i className="flaticon-warning"></i></div>
      <div className="alert-text">No records found!</div>
     </div>
    
  )
}

export function WarningDiv(props: {message: string}){

  return (
    <div className="alert alert-outline-warning fade show" role="alert">
      <div className="alert-icon"><i className="flaticon-warning"/></div>
      <div className="alert-text">{props.message}</div>
    </div>
  )
}

export function SuccessDiv(props: {message: string}){

  return (
    <div className="alert alert-success" role="alert">
      <div className="alert-icon"><i className="flaticon-warning"/></div>
      <div className="alert-text">{props.message}</div>
    </div>
  )
}