// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Leaves_date_time_line__nFi6c {\n  display: grid;\n  grid-template-columns: 1fr 1fr;\n  grid-column-gap: 10px;\n  column-gap: 10px;\n  width: 100%;\n  margin-top: 1rem;\n}\n\n.Leaves_section_label_grid__15tuD {\n  grid-column: 1/-1;\n}\n\n.Leaves_section_label__15jTd {\n  margin-top: 2rem;\n}\n\n.Leaves_submit_section__1jG2p {\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n  padding: 25px 0 !important;\n}\n.Leaves_submit_section__1jG2p > div > *:nth-child(1) {\n  margin-right: 10px;\n}\n.Leaves_submit_section__1jG2p > div > *:nth-child(2) {\n  margin-right: 1.25rem;\n}", ""]);
// Exports
exports.locals = {
	"date_time_line": "Leaves_date_time_line__nFi6c",
	"section_label_grid": "Leaves_section_label_grid__15tuD",
	"section_label": "Leaves_section_label__15jTd",
	"submit_section": "Leaves_submit_section__1jG2p"
};
module.exports = exports;
