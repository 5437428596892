import * as React from 'react';
import {SearchInput, SearchSelect} from "../../common/form/SearchInput";
import {IState as CommonState} from "../../common/State";
import {ShowRecords} from "../../common/ShowRecords";
import {CONST, ExportButton, getQueryStringParams} from "../../common/Common";
import {Icons} from "../../common/Icon";
import {actionButton} from "../../common/table/BlukActionButtons";
import {RouteComponentProps} from "react-router";
import {CommonSettingModel} from "../../model/CommonSetting";
import {ListBreadcrumb} from './Data';
import {ActionLink, CreateLink, EditLink, GeneratePermitLink} from "../../common/page/CreateButton";
import {ListForm, ListHeader, ListingLayout} from "../../layout/Listing";
import {default as Lang} from "../../common/lang/Lang";
import CommonFilter from './filter/CommonFilter';
import _ from "lodash";
import {list_key} from "../../helpers/OrderHelper";


interface IState extends CommonState{
  UserData: Array<CommonSettingModel>;
 }


export class CommonListing extends React.Component<RouteComponentProps, IState> {
  state: IState;
  records:React.RefObject<ShowRecords> = React.createRef();

  shouldComponentUpdate(nextProps: Readonly<RouteComponentProps>, nextState: Readonly<IState>, nextContext: any): boolean {
      
    return  this.state !== nextState;
     
 }

  filterRecords = (data?: any) => {
    if( this.records && this.records.current !== null){
      if(data !== undefined){
        this.records.current.setFormFields(data);
      }else {
        this.records.current.setFormFields();
      }
    }
  }

   

    showContent = (data: CommonSettingModel, index: number) => {
    
        let cols = [];
        cols.push(...[
            // <td key={index}>{this.records.current.checkbox(data.id)}</td>,
            this.records.current.createdTd("id", index),
           
            this.records.current.createdTd("setting_name", () =>{return (data.setting_name !== null) ? data.setting_name : '--'; }),
            this.records.current.createdTd("setting_value", () =>{return (data.setting_value !== null) ? data.setting_value : '--'; }),
         

     this.records.current.createdTd("action", () => {
                    return (
                        <div>
                            <EditLink scope={'common-setting.update'} to={`/settings/common-setting/edit/${data.id}`} data-skin={'dark'}  title={Lang.edit_setting} />

                        </div>
                    )
                })

        ]);
        cols = _.compact(cols);

        return (
            <tr key={list_key(data.id)}>{cols}</tr>
        );
    
  }
 


  render() {

    return (
        <ListingLayout breadcrumb={ListBreadcrumb}>
          <ListHeader title={'common_setting'} icon={Icons.setting}>
            <ExportButton records={this.records} fileName={Lang.exportCommonSetting}/>
            &nbsp;
            <CreateLink to={`${this.props.match.url}/create`} scope={'common-setting.store'}  />
          </ListHeader>

          <ListForm
            form={(
              <CommonFilter   getResults={this.filterRecords} /> 
          )}

            table={(

              <ShowRecords
                  url={"settings/common-setting"}
                  showRecords={this.showContent}
                  showCheckbox={false}
                  ref={this.records}
                isNeedAction={false}
                  actionButtons={actionButton({records: this.records, 'url': "settings/language/bulk-action"})}
              />
          )}
          />
        </ListingLayout>
    )
  }
}