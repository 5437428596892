
declare var window: any;

export const CommonValidations = function () {

  $(document).ready(function () {

    $( "#edit_role" ).validate({
      // define validation rules
      rules: {
        setting_name: {
          required: true
        },
      }
      
    });
  });
}