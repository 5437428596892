import React, {useEffect, useState} from 'react';
import Lang from "../../common/lang/Lang";
import {CommonSubmitButton, Select} from "../../common";
import {QuizModel, QuizType, WeLearnModel} from "../../model";
import {LearnService, QuizService} from "../../services";
import {QuizValidations} from "./Validations";

export function QuizForm (props: any) {

  const [form, setForm] = useState({quiz_type: props.from === QuizType.ONLINE_TEST ? QuizType.ONLINE_TEST: QuizType.ONLINE_QUIZ} as QuizModel);
  const [learn, setWeLearn] = useState([] as Array<WeLearnModel>);


  useEffect(() => {
    getLearnData()
  },[])

  function handleSubmit(e) {
    e.preventDefault();
    if($( "#quiz_form" ).valid()){
      QuizService.store(form).then(res => {
        props.history.push(`edit/${res.id}/questions`)
      })
    }

  }

  function  handleChange  (event: React.ChangeEvent<HTMLElement>){
    const target : any = event.target;
    const value = target.type === 'file' ? target.files[0] :  target.value;
    const name = target.name;

    if(name === 'quiz_type' && value == QuizType.WE_LEARN){
      getLearnData();
    }

    setForm({...form,  [name]: value});
  }

  function getLearnData() {
    LearnService.we_learn_list().then(res => {
      setWeLearn(res);
    })
  }
  QuizValidations();

  return (
    <form className="kt-form kt-form--label-right" id={'quiz_form'} onSubmit={handleSubmit}>
      <div className="kt-portlet__body text-center">
        <div className={'col-md-8'} style={{paddingTop:20}}>
          <input type={'hidden'} id={'hidden_quiz_type'} defaultValue={`${form.quiz_type}`}/>
          { props.from !== QuizType.ONLINE_TEST &&
            <div className="form-group row">
              <label className={'col-2 col-form-label'}> {Lang.quiz_type}<span className={'required'}>*</span></label>
              <div className="col-10">
                <div className="kt-radio-inline">
                  <label className="kt-radio kt-radio--bold kt-radio--brand" style={{width: '20%'}}>
                    <input type={'radio'} onChange={handleChange} value={QuizType.ONLINE_QUIZ} name={'quiz_type'} checked={form.quiz_type == QuizType.ONLINE_QUIZ} className="form-control" />
                    {Lang.online_quiz}
                    <span/>
                  </label>
                  <label className="kt-radio kt-radio--bold kt-radio--brand" style={{width: '40%'}}>
                    <input type={'radio'} onChange={handleChange} value={QuizType.WE_LEARN} name={'quiz_type'} className="form-control" />
                    {Lang.is_quis_learn_p}
                    <span/>
                  </label>
                </div>
              </div>
            </div>
          }

          {
            form.quiz_type == QuizType.WE_LEARN &&
            <div className="form-group row">
              <label className={'col-2 col-form-label'}>{Lang.select_learn}<span className={'required'}>*</span></label>
              <div className="col-10">
                <Select className="form-control" name={'we_learn_id'} id={'we_learn_id'} value={form.we_learn_id} onChange={handleChange} >
                  <option value={''}>Select</option>
                  {learn.map((item, index) => {
                    return <option key={item.id} value={item.id}>{item.title}</option>
                  })}
                </Select>
              </div>
            </div>
          }
          <div className="form-group row">
            <label className={'col-2 col-form-label'}>{Lang.title} <span className={'required'}>*</span></label>
            <div className="col-10">
              <input type={'text'} onChange={handleChange} name={'title'} value={form.title || ''} className="form-control" placeholder={Lang.enter_title}/>
            </div>
          </div>

          <div className="form-group row">
            <label className={'col-2 col-form-label'}>{Lang.description} <span className={'required'}>*</span></label>
            <div className="col-10">
              <textarea onChange={handleChange}  name={'description'}  value={form.description || ''} className="form-control" placeholder={Lang.enter_description} />
            </div>
          </div>
          <div className="form-group row">
            <label className={'col-2 col-form-label'}>{Lang.image} </label>
            <div className="col-10">
              <div className="custom-file">
                <input type={'file'} onChange={handleChange}  name={'image'} className="custom-file-input"/>
                <label className="custom-file-label" htmlFor="customFile"/>
              </div>
            </div>
          </div>

          <div className="form-group row">
            <label className={'col-2 col-form-label'}>{Lang.total_marks} <span className={'required'}>*</span></label>
            <div className="col-10">
              <input type={'text'}  onChange={handleChange}  name={'total_marks'}  value={form.total_marks || ''} className="form-control" placeholder={Lang.placeholder_total_marks} />
            </div>
          </div>

          <div className="form-group row">
            <label className={'col-2 col-form-label'}>{Lang.min_marks} <span className={'required'}>*</span></label>
            <div className="col-10">
              <input type={'text'}  onChange={handleChange}  name={'min_marks'}  value={form.min_marks || ''} className="form-control" placeholder={Lang.placeholder_min_marks} />
            </div>
          </div>

          <div className="form-group row">
            <label className={'col-2 col-form-label'}>{Lang.total_time}</label>
            <div className="col-10">
              <input type={'text'}  onChange={handleChange}  name={'time'}  value={form.time || ''} className="form-control" placeholder={Lang.placeholder_total_time} />
            </div>
          </div>
        </div>
      </div>
      <CommonSubmitButton/>
    </form>
  );
}