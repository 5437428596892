export interface RoleModel {
  id?: number;
  name: string;
  permissions: string;
  status?: number;
}


export const RoleValues = (data?: RoleModel) => {
  return {
    name: data !== undefined ? data.name : '',
    permissions: data !== undefined ? data.permissions.split(",") : new Array(),
  }
}