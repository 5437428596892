import React, {useEffect, useState} from 'react';
import _ from 'lodash';
import API from "../common/AxoisClient";
import {AxiosResponse} from "axios";
import Lang from "../common/lang/Lang";
import {showNotification} from "../common";
let fileDownload = require('js-file-download');
export function WeFileViewer (props: any) {
    const [orderId,setOrderId] = useState(0);
    const [fileData,setFileData] = useState('');
    const [documentType,setDocumentType] = useState('');
    const [fileUrl,setFileUrl] = useState('');
    const [file_name,setFileName] = useState('');

    useEffect(()=>{
        if(!_.isUndefined(props.match.params.id) && !_.isEmpty(props.match.params.id)) {
           setOrderId(props.match.params.id)
        }
        if(!_.isUndefined(props.match.params.documentType) && !_.isEmpty(props.match.params.documentType)) {
            setDocumentType(props.match.params.documentType)
        }
        let search:any = window.location.search;
        let params:any = new URLSearchParams(search);
        setFileName(params.get('file_name'));
        if(!_.isEmpty(params.get('file_name'))) {
            document.title = params.get('file_name');
        }
    },[]);

    useEffect(()=>{
        //pageLoaderStart();
        let urlParams:any = new URLSearchParams(window.location.search);
        if(urlParams.has('url')) {
          let fileUrlPath:string = getUrlParameter('url');
          setFileUrl(fileUrlPath);
          $('body').addClass('main-scroll-hidden');
        }
    },[]);

    function getUrlParameter(name:any) {
        name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
        let regex:any = new RegExp('[\\?&]' + name + '=([^&#]*)');
        let results:any = regex.exec(window.location.search);
        return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
    };

    useEffect(() => {
        if(orderId>0) {
            API.get(`orders/generate-file/${orderId}`,{responseType: 'blob',params:{
                  "export":documentType,
                }}).then((res: any) => {
                    //pageLoaderStop();
                    if(!_.isEmpty(file_name)) {
                        if(!_.isEmpty(file_name)) {
                            showNotification("success", 'File has been successfully downloaded');
                        }
                        fileDownload(res.data, file_name);
                        setTimeout(function () {
                            window.close ();
                        },3000);
                    } else {
                        let file = window.URL.createObjectURL(res.data);
                        /*if(isIE) {
                            showNotification("success", 'File has been successfully downloaded');
                            fileDownload(res.data, file_name);
                            setTimeout(function () {
                                window.close ();
                            },3000);
                        }*/
                        setFileData(file);
                    }
            }).catch((e:any) => {
               //pageLoaderStop();
               if(e.status == 422) {
                   showNotification("danger", 'Invalid Order');
                   setTimeout(function () {
                       window.close ();
                   },3000);
               }
            });
        }
    },[orderId]);


    return (
        <>
            <div className="alert alert-solid-brand" role="alert" style={{color: '#299798',fontWeight: 'bold'}}>
                {Lang.please_wait}
            </div>
            {_.isEmpty(file_name) && !_.isEmpty(fileData) &&
                <iframe id={'weFileViewerIframe'}  className={'documentViewInWeb img-responsive'} style={{minHeight:'100vh'}} width="100%"  src={fileData}>
                    <p>{'Your browser is not supported to pdf view. Please upgrade to the latest version'}</p>
                </iframe>
            }
        </>
    );
}