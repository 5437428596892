import React, {useState} from 'react';
import {Icons} from "../../../common";
import {Link, Route} from "react-router-dom";
import {EditQuiz} from "./EditQuiz";
import {getQuizContent} from "./Data";
import {Questions} from "./Questions";

export function QuizTabs(props: any) {
  const {id} = props.match.params;
  const info = getQuizContent(props.from);
  const [active, setActive] = useState(props.location.pathname.search('questions') > 0 ? 'questions' : 'info');

  return (
    <div className="kt-grid  kt-wizard-v2 kt-wizard-v2--white" id="kt_wizard_v2" data-ktwizard-state="step-first">

      <div className="kt-grid__item kt-wizard-v2__aside">
        <div className="kt-wizard-v2__nav">
          <div className="kt-wizard-v2__nav-items">
            <Link className="kt-wizard-v2__nav-item" to={`${info.edit_link}${id}`} data-ktwizard-type="step"  data-ktwizard-state={active === 'info' ? 'current': ''} onClick={() => setActive('info')}>
              <div className="kt-wizard-v2__nav-body">
                <div className="kt-wizard-v2__nav-icon">
                  <i className={Icons.company_info}/>
                </div>
                <div className="kt-wizard-v2__nav-label">
                  <div className="kt-wizard-v2__nav-label-title">
                    {info.first_title}
                  </div>
                  <div className="kt-wizard-v2__nav-label-desc">
                    {info.first_title_info}
                  </div>
                </div>
              </div>
            </Link>

            <Link className="kt-wizard-v2__nav-item" to={`${info.edit_link}${id}/questions`} data-ktwizard-type="step"  data-ktwizard-state={active === 'questions' ? 'current': ''} onClick={() => setActive('questions')}>
              <div className="kt-wizard-v2__nav-body">
                <div className="kt-wizard-v2__nav-icon">
                  <i className={Icons.question}/>
                </div>
                <div className="kt-wizard-v2__nav-label">
                  <div className="kt-wizard-v2__nav-label-title">
                    {info.second_title}
                  </div>
                  <div className="kt-wizard-v2__nav-label-desc">
                    {info.second_title_info}
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
      <div className="kt-grid__item kt-grid__item--fluid kt-wizard-v2__wrapper">
        <Route exact path={`${props.match.path}`} render={(props) => { return <EditQuiz id={id} title={info.edit_title} />  }} />
        <Route path={`${props.match.path}/questions`}render={(props) => { return <Questions id={id} title={info.question_title} />  }}/>
      </div>
    </div>
  )
}