import * as React from 'react';
import {Breadcrumb} from "../../common/page/Breadcrumb";
import Lang from "../../common/lang/Lang";
import {EditBreadcrumb} from "./Data";
import API from './../../common/AxoisClient';
import {showNotification} from "../../common/Common";
import {AdminUserTabs} from "./Tabs";
import {AdminUserValidations} from "./Validations";
import { UserModel} from "../../model/User";
import {RouteComponentProps} from "react-router";
import {PleaseWait} from "../../common/page/PleaseWait";
import {PermissionModel} from "../../model/Permission";
import {ChoosePermission} from "../../permissions/role/ChoosePermission";
import {SubmitButton,SwitchLoader} from "../../common";
import {LoaderType} from "../../common/Loader";
import _ from 'lodash';
declare var window: any;

interface IState{
  id: number;
  form: {
    permissions: Array<string>
  }
  user?: UserModel;
  scopes: Array<any>;
}

export class UserPermission extends React.Component<RouteComponentProps, IState> {
  state: IState;

  constructor(props: any){
    super(props);

    this.state = {
      form: {
        permissions: []
      },
      id: props.match.params.id,
      scopes: [],
    }
    AdminUserValidations();
  }

   CheckAll = (event: React.ChangeEvent<HTMLInputElement>, role: Array<PermissionModel>) => {
        let scopes = [];
        role.map((item: PermissionModel) => {
          scopes.push(item.scope);
        })
      //  let remove_item = scopes.toString();
         if(event.target.checked === true) {
          let old_permissions = this.state.form.permissions;
          let new_permissions = _.concat(old_permissions, scopes);
           this.setState({
             form: { ...this.state.form, permissions: new_permissions}
           });
        } else {
            let old_permissions = this.state.form.permissions;
            let  new_permissions =  _.pullAll(old_permissions, scopes);
           this.setState({
           form: { ...this.state.form, permissions: new_permissions}
         });
       }
     }

     CheckedAllValues = (event: React.ChangeEvent<HTMLInputElement>, rolesPermission) => {
              let scopes = [];
              Object.entries(rolesPermission).map((key : any, value: number) =>  {
                  key[1].map((item: PermissionModel) => {
                      scopes.push(item.scope);

                  })
              })
              if(event.target.checked === true) {
                  let old_permissions = this.state.form.permissions;
                  let new_permissions = _.concat(old_permissions, scopes);
                  this.setState({
                      form: { ...this.state.form, permissions: new_permissions}
                  });
              } else {
                  let old_permissions = this.state.form.permissions;
                  let  new_permissions =  _.pullAll(old_permissions, scopes);
                  this.setState({
                      form: { ...this.state.form, permissions: new_permissions}
                  });
              }

          }

  componentDidMount() {

    API.get(`users/admin-users/${this.state.id}`).then((res: any) => {
      this.setState({
        form: { ...this.state.form, permissions: res.data.permission !== null ? res.data.permission.permissions.split(",") : []}
      })
    })

    API.get('permission/role/create').then((res: any) => {
      this.setState({
        scopes: res.data
      }, () => {
        window.KTApp.initTooltips();
      })
    });
  }

  handleScopes = (event: React.ChangeEvent<HTMLInputElement>, id: string) => {
    if(event.target.checked === true){
      this.setState(prevState => {
        return { form: { ...this.state.form, permissions: [...prevState.form.permissions, id]} }
      });
    } else {
      this.setState({ form: { ...this.state.form, permissions: this.state.form.permissions.filter((item: string) => {
            return item !== id
          })} });
    }
  }


  handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    SwitchLoader(LoaderType.BUTTON);
    API.put(`users/admin-users/${this.state.id}`, this.state.form, {params: {from: 'permission'}}).then((res) => {
      showNotification("success", res.data.message);
    })

  }


  render() {
    return (
      <div>
        <Breadcrumb data={EditBreadcrumb}/>

        <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
          <div className="kt-portlet">
            <div className="kt-portlet__body">
              <div className="kt-grid  kt-wizard-v2 kt-wizard-v2--white" id="kt_wizard_v2" data-ktwizard-state="step-first">
               <AdminUserTabs active={'time'} user_id={this.state.id}/>
                <div className="kt-grid__item kt-grid__item--fluid kt-wizard-v2__wrapper">
                  <form className="kt-form" id="create_company"  onSubmit={this.handleSubmit} style={{width: '100%'}}>
                    <div className="kt-wizard-v2__content" data-ktwizard-type="step-content" data-ktwizard-state="current">
                      <div className="kt-heading kt-heading--md">{Lang.edit_user_permission}</div>
                      <div className="kt-form__section kt-form__section--first">
                        <div className="kt-wizard-v2__form">

                          <ChoosePermission  CheckAll={this.CheckAll} CheckedAllValues={this.CheckedAllValues} onChange={this.handleScopes} permissions={this.state.form.permissions} scopes={this.state.scopes}/>
                        </div>

                        <div className="kt-form__actions">
                          <div className="kt-font-bold kt-font-transform-u" data-ktwizard-type="action-next">
                           <SubmitButton className="btn btn-primary" label={Lang.submit}/>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    )
  }
}