import {QuizQuestionModel} from "./QuizQuestion";

export interface QuizModel {
  id: number;
  quiz_type: number;
  we_learn_id: number;
  title: string;
  description: string;
  image: string;
  total_marks: number;
  min_marks: number;
  time: string;
  status: number;
  created_at: number;
  created_by: number;

  o_image? :string;
  questions?: Array<QuizQuestionModel>
}

export const QuizType = {
  ONLINE_QUIZ: 1,
  ONLINE_TEST: 2,
  WE_LEARN: 3
};

export const QuizTypeTitle = {
  [QuizType.ONLINE_QUIZ] : 'Online Quiz',
  [QuizType.ONLINE_TEST] : 'Online Test',
  [QuizType.WE_LEARN] : 'We Learn',
};