import {QuizType} from "../../../model";
import Lang from "../../../common/lang/Lang";

export function getQuizContent(from) {

  if(from === QuizType.ONLINE_TEST){
    return {
      edit_link: '/learn/online-test/edit/',
      question_link: '/learn/online-test/edit/',
      edit_title: Lang.edit_online_test_title,
      question_title: Lang.question_title_test,
      first_title: Lang.edit_online_test,
      first_title_info: Lang.edit_online_test_info,
      second_title: Lang.edit_online_test_question,
      second_title_info: Lang.edit_online_test_question_info,
    }
  } else {
    return {
      edit_link: '/learn/quiz/edit/',
      question_link: '/learn/quiz/edit/',
      edit_title: Lang.edit_online_quiz_title,
      question_title: Lang.question_title_quiz,
      first_title: Lang.edit_online_quiz,
      first_title_info: Lang.edit_online_quiz_info,
      second_title: Lang.edit_online_quiz_question,
      second_title_info: Lang.edit_online_quiz_question_info,
    }
  }
}