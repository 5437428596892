// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Invoicing_container__3xmzP {\n  padding: 21px 69px;\n  display: grid;\n  grid-template-columns: 1fr 1fr;\n  grid-template-rows: 175px 175px 1fr;\n  grid-template-areas: \"running next\" \"running info\" \"table table\";\n  grid-column-gap: 100px;\n  column-gap: 100px;\n}\n\n.Invoicing_running_job__3KDNe {\n  grid-area: running;\n}\n\n.Invoicing_table__13Tft {\n  grid-area: table;\n}\n\n.Invoicing_section_title__2V_xI {\n  display: flex;\n  align-items: center;\n  color: black;\n  margin-bottom: 1rem;\n}\n.Invoicing_section_title__2V_xI h1 {\n  font-weight: 400;\n  font-size: 30px;\n}\n.Invoicing_section_title__2V_xI div {\n  height: 31px;\n  width: 31px;\n  margin-left: 2rem;\n  display: grid;\n  place-content: center;\n  color: white;\n  font-size: 1.4rem;\n}\n\n.Invoicing_title_icon__34Cwh {\n  padding: 5px;\n  border-radius: 50%;\n  transform: translateY(-4px);\n}", ""]);
// Exports
exports.locals = {
	"container": "Invoicing_container__3xmzP",
	"running_job": "Invoicing_running_job__3KDNe",
	"table": "Invoicing_table__13Tft",
	"section_title": "Invoicing_section_title__2V_xI",
	"title_icon": "Invoicing_title_icon__34Cwh"
};
module.exports = exports;
