import * as React from 'react';
import {Link} from "react-router-dom";
import {Icons} from "../../common/Icon";
import Lang from "../../common/lang/Lang";

interface AdminUserTabsSchemas {
  active: string;
  user_id: number
}

export class AdminUserTabs extends React.Component<AdminUserTabsSchemas> {

  render() {
    return (
      <div className="kt-grid__item kt-wizard-v2__aside">
        <div className="kt-wizard-v2__nav">
          <div className="kt-wizard-v2__nav-items">
            <Link className="kt-wizard-v2__nav-item" to={`/users/admin-users/edit/${this.props.user_id}`} data-ktwizard-type="step" data-ktwizard-state={this.props.active === "info" ? "current" : ''} >
              <div className="kt-wizard-v2__nav-body">
                <div className="kt-wizard-v2__nav-icon">
                  <i className={Icons.company_info}/>
                </div>
                <div className="kt-wizard-v2__nav-label">
                  <div className="kt-wizard-v2__nav-label-title">
                    {Lang.user_info}
                  </div>
                  <div className="kt-wizard-v2__nav-label-desc">
                    {Lang.user_details}
                  </div>
                </div>
              </div>
            </Link>

            <Link className="kt-wizard-v2__nav-item" to={`/users/admin-users/edit/${this.props.user_id}/permissions`} data-ktwizard-type="step" data-ktwizard-state={this.props.active === "time" ? "current" : ''}>
              <div className="kt-wizard-v2__nav-body">
                <div className="kt-wizard-v2__nav-icon">
                  <i className={Icons.company_time}/>
                </div>
                <div className="kt-wizard-v2__nav-label">
                  <div className="kt-wizard-v2__nav-label-title">
                    {Lang.user_permission}
                  </div>
                  <div className="kt-wizard-v2__nav-label-desc">
                    {Lang.user_permission_details}
                  </div>
                </div>
              </div>
            </Link>

            <Link className="kt-wizard-v2__nav-item" to={`/users/admin-users/edit/${this.props.user_id}/change-password`} data-ktwizard-type="step" data-ktwizard-state={this.props.active === "password" ? "current" : ''}>
              <div className="kt-wizard-v2__nav-body">
                <div className="kt-wizard-v2__nav-icon">
                  <i className={Icons.password}/>
                </div>
                <div className="kt-wizard-v2__nav-label">
                  <div className="kt-wizard-v2__nav-label-title">
                    {Lang.change_password}
                  </div>
                  <div className="kt-wizard-v2__nav-label-desc">
                    {Lang.change_password_details}
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}