import React, { useState } from 'react';
import './UpdateOTPSettings.scss';

type OTPSetting = 'on' | 'off' | 'neutral';

const UpdateOTPSettings: React.FC = () => {
  const [otpSetting, setOtpSetting] = useState<OTPSetting>('neutral');

  const handleOtpSettingChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOtpSetting(e.target.value as OTPSetting);
  };

  return (
    <div className="update-otp-settings">
      <h2>OTP Settings</h2>
      <div className="form-group">
        <label>OTP:</label>
        <label>
          <div className='kt-radio'>

          <input
            type="radio"
            name="otp-setting"
            value="on"
            checked={otpSetting === 'on'}
            onChange={handleOtpSettingChange}
            />
          On
            </div>
        </label>
        <label>
          <input
            type="radio"
            name="otp-setting"
            value="off"
            checked={otpSetting === 'off'}
            onChange={handleOtpSettingChange}
          />
          Off
        </label>
        <label>
          <input
            type="radio"
            name="otp-setting"
            value="neutral"
            checked={otpSetting === 'neutral'}
            onChange={handleOtpSettingChange}
          />
          Neutral
        </label>
      </div>
    </div>
  );
};

export default UpdateOTPSettings;