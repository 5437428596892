// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ActivityLogFilter_container__3fHkM {\n  display: flex;\n  margin-bottom: -4rem;\n  margin-top: -4rem;\n  transform: translateY(4rem);\n  position: relative;\n  z-index: 999999999999;\n  flex-wrap: wrap;\n}\n.ActivityLogFilter_container__3fHkM > * {\n  margin: 0.5rem 0.2rem;\n  padding: 0;\n}\n\n.ActivityLogFilter_line__yMCAF {\n  display: grid;\n  grid-template-columns: 30rem 30rem 30rem;\n  grid-column-gap: 3rem;\n  column-gap: 3rem;\n  grid-row-gap: 2rem;\n  row-gap: 2rem;\n}\n.ActivityLogFilter_line__yMCAF > * {\n  width: 30rem;\n}\n\n.ActivityLogFilter_search__2VTEd {\n  padding: 0;\n}\n\n.ActivityLogFilter_user__3Q1hm {\n  width: 30rem;\n}\n\n.ActivityLogFilter_reset_button__1Bnlp {\n  width: -moz-fit-content;\n  width: fit-content;\n  flex: unset;\n}\n\n@media screen and (max-width: 1366px) {\n  .ActivityLogFilter_line__yMCAF {\n    grid-template-columns: 30rem 30rem;\n  }\n}", ""]);
// Exports
exports.locals = {
	"container": "ActivityLogFilter_container__3fHkM",
	"line": "ActivityLogFilter_line__yMCAF",
	"search": "ActivityLogFilter_search__2VTEd",
	"user": "ActivityLogFilter_user__3Q1hm",
	"reset_button": "ActivityLogFilter_reset_button__1Bnlp"
};
module.exports = exports;
