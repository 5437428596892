// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Search_search__h3CUF {\n  display: flex;\n  align-items: center;\n  justify-content: flex-start;\n  position: relative;\n  border: 1px solid #AAAAAA;\n  padding: 0.6rem;\n  border-radius: 5px;\n  cursor: text;\n}\n\n.Search_label__3L9Kg {\n  position: absolute;\n  align-items: center;\n  margin: 0;\n  pointer-events: none;\n}\n\n.Search_hover__34jm7 {\n  display: inline-block;\n  padding: 0 0.4rem;\n  transform: translateY(-1.5rem);\n  background-color: white;\n  width: -moz-fit-content;\n  width: fit-content;\n}\n\n.Search_hoverable__1k5g4 {\n  transition: all 0.2s ease;\n}\n\n.Search_hover_down_animation__19PJb {\n  animation: Search_dropDown__115eD 0.2s ease;\n}\n\n.Search_input__1cf1o {\n  border: none;\n  outline: none;\n  margin-left: 1.1rem;\n  width: 100%;\n  height: 100%;\n}\n\n@keyframes Search_dropDown__115eD {\n  from {\n    transform: translateY(-1.5rem);\n    padding: 0 0.4rem;\n  }\n  to {\n    transform: translateY(0);\n    padding: 0;\n  }\n}", ""]);
// Exports
exports.locals = {
	"search": "Search_search__h3CUF",
	"label": "Search_label__3L9Kg",
	"hover": "Search_hover__34jm7",
	"hoverable": "Search_hoverable__1k5g4",
	"hover_down_animation": "Search_hover_down_animation__19PJb",
	"dropDown": "Search_dropDown__115eD",
	"input": "Search_input__1cf1o"
};
module.exports = exports;
