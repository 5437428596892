import {QuizQuestionOptionModel} from "./QuizQuestionOption";
import Lang from "../common/lang/Lang";
import {QuizAnswerModel} from "./QuizAnswer";

export interface QuizQuestionModel {
  id: number;
  quiz_id: number;
  question_type: number;
  question: string;
  total_marks: number;
  status: number;
  created_by: number;

  options: Array<QuizQuestionOptionModel>;
  quiz_answers?: QuizAnswerModel
}

export const QuestionType = {
  SUBJECTIVE: 1,
  OBJECTIVE: 2,
  MULTI_CHOICE: 3
};


export const QuestionTypeTitle = {
  [QuestionType.SUBJECTIVE]: Lang.subjective,
  [QuestionType.OBJECTIVE]: Lang.OBJECTIVE,
  [QuestionType.MULTI_CHOICE]: Lang.MULTI_CHOICE
};