// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".CustomerEmailInput_item__2tMdC {\n  display: flex;\n  justify-content: space-between;\n}\n\n.CustomerEmailInput_company_info__2YtnG {\n  display: flex;\n  flex-direction: column;\n}\n.CustomerEmailInput_company_info__2YtnG > span:nth-child(1) {\n  font-weight: bold;\n}\n\n.CustomerEmailInput_special_company_indicator__1Um1Z {\n  color: var(--green);\n}", ""]);
// Exports
exports.locals = {
	"item": "CustomerEmailInput_item__2tMdC",
	"company_info": "CustomerEmailInput_company_info__2YtnG",
	"special_company_indicator": "CustomerEmailInput_special_company_indicator__1Um1Z"
};
module.exports = exports;
