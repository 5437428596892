// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Table_table__c64F6 {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  width: 100%;\n  font-weight: 400;\n  height: 500px;\n  overflow-y: scroll;\n}\n.Table_table__c64F6 > div.Table_row__c-pg0:nth-child(even) {\n  background-color: #EFEFEF;\n}\n\n.Table_heading_row__30SmB {\n  display: grid;\n  grid-template-columns: 1fr 3fr 1fr 1.5fr 1fr;\n  width: 100%;\n  height: 58px;\n  align-content: center;\n  font-size: 1.2rem;\n  font-weight: 400;\n  color: black;\n  padding: 0 20px;\n  flex: 0 0 48px;\n}\n\n.Table_row__c-pg0 {\n  display: grid;\n  grid-template-columns: 1fr 3fr 1fr 1.5fr 1fr;\n  align-content: center;\n  width: 100%;\n  height: 61px;\n  color: black;\n  padding: 0 20px;\n  flex: 0 0 51px;\n}\n.Table_row__c-pg0 > * {\n  display: flex;\n  align-items: center;\n}", ""]);
// Exports
exports.locals = {
	"table": "Table_table__c64F6",
	"row": "Table_row__c-pg0",
	"heading_row": "Table_heading_row__30SmB"
};
module.exports = exports;
