import React from 'react';
import { EditBreadcrumb} from "./Data";
import {QuizTabs} from "../quiz-form/edit/QuizTabs";
import {Breadcrumb} from "../../common";
import {QuizType} from "../../model";

export function EditOnlineTest(props) {

  return (
    <div>
      <Breadcrumb data={EditBreadcrumb}/>
      <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
        <div className="kt-portlet">
          <div className="kt-portlet__body">
            <QuizTabs {...props} from={QuizType.ONLINE_TEST}/>
          </div>
        </div>
      </div>
    </div>
  );
}