import Lang from "../../common/lang/Lang";

export const urls = {
    users: "/users/admin-users"
}

export const EditProfileBreadcrumb = [
    {
        label: Lang.users,
        url: urls.users
    },
    {
        label: Lang.my_profile,
        url: '#'
    }
];