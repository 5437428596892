import API from "../common/AxoisClient";
import {state_to_form_data} from "../helpers/UserHelper";
import {SwitchLoader, LoaderType, showConfirmBox} from "../common";
import {Subject} from "rxjs/index";
import {ActionType} from "../learn/quiz-form/questions/QuestionReducer";
import {QuizQuestionModel} from "../model";

export class LearnService {

  static host = 'learn';

  static instance = new LearnService();

  static we_learn = [];

  static URLS = {
    WE_LEARN: {
      LIST: `/learn/we-learn`,
      STORE:`/learn/we-learn`,
      UPDATE:`/learn/we-learn/`,
      LISTING:`/learn/we-learn/listing-data`,
    }
  };

  /**
   *  store we learn data
   * @param data
   * @returns {Promise<T>}
   */
  public static async store(data) {
    SwitchLoader(LoaderType.BUTTON);
    const res = await API.post(this.URLS.WE_LEARN.STORE, state_to_form_data(data));
    return res.data;
  }


  /**
   *  update we learn data
   * @param id
   * @param data
   * @returns {Promise<T>}
   */

  public static async update(id, data) {
    SwitchLoader(LoaderType.BUTTON);
    const res = await API.post(`${this.URLS.WE_LEARN.UPDATE}${id}`, state_to_form_data({...data, '_method': 'PUT'}));
    return res.data;
  }

  /**
   * show we learn data
   * @param id
   * @returns {Promise<T>}
   */
  public static async view(id) {
    const res = await API.get(`${this.URLS.WE_LEARN.STORE}/${id}`);
    return res.data;
  }

  public static async we_learn_list() {
    if(this.we_learn.length <= 0){
      const countries = await API.get(this.URLS.WE_LEARN.LISTING);
      this.we_learn = countries.data;
    }
    return this.we_learn;
  }
}


export class QuizService {

  static URLS = {
      LIST: `/learn/quiz`,
      STORE:`/learn/quiz`,
      UPDATE:`/learn/quiz/`,
  };

  public static async store(data) {
    SwitchLoader(LoaderType.BUTTON);
    const res = await API.post(this.URLS.STORE, state_to_form_data(data));
    return res.data;
  }

  /**
   * show we quiz data
   * @param id
   * @returns {Promise<T>}
   */
  public static async view(id) {
    const res = await API.get(`${this.URLS.LIST}/${id}`);
    return res.data;
  }

  /**
   *  update we quiz data
   * @param id
   * @param data
   * @returns {Promise<T>}
   */

  public static async update(id, data) {
    SwitchLoader(LoaderType.BUTTON);
    const res = await API.post(`${this.URLS.UPDATE}${id}`, state_to_form_data({...data, '_method': 'PUT'}));
    return res.data;
  }
}



const subject = new Subject();
export const QuestionPopupService = {
  addQuestion: (quiz_id) => subject.next( {quiz_id: quiz_id, action: ActionType.ADD_QUESTION_POPUP}),
  editQuestion: (quiz_id, question) => subject.next( {quiz_id: quiz_id, question: question, action: ActionType.EDIT_QUESTION_POPUP}),
  hide: () => subject.next({action: ActionType.HIDE_POPUP}),
  handleEvent: () => subject.asObservable()
};



const ql = new Subject();
export const QuestionListService = {
  list: (quiz_id) => {
    QuestionService.getQuestions(quiz_id).then(res => {
      ql.next(res)
    })
  },
  add: (data: QuizQuestionModel) => ql.next({type: 'add', data: data}),
  edit: (data: QuizQuestionModel) => ql.next({type: 'remove', data: data}),
  questions: () => ql.asObservable()
};


export class QuestionService {

  static URLS = {
      LIST: `/learn/questions`,
      STORE:`/learn/questions/`,
      UPDATE:`/learn/questions/`,
  };

  public static async store(quiz_id, data) {
    SwitchLoader(LoaderType.BUTTON);
    const res = await API.post(`${this.URLS.STORE}${quiz_id}`, data);
    return res.data;
  }

  public static async getQuestions(quiz_id) {
    const res = await API.get(`${this.URLS.LIST}/${quiz_id}`);
    return res.data;
  }

  public static async deleteQuestion(question_id) {
    return showConfirmBox("Are you sure that you want to delete this question", async () => {
      const res = await API.post(`${this.URLS.LIST}/${question_id}`, {'_method': 'DELETE'});
      return res.data;
    })

  }
}