import API from "../common/AxoisClient";
import {Subject} from "rxjs/index";
import { EmailModel } from "../model/Email";
import { SystemEmailModel } from "../model/SystemEmail";
import _ from 'lodash';
import { Static } from "react-bootstrap/lib/FormControl";
 
class NewletterContent {

  static instance = new NewletterContent();

  mailtemplate = [];
  systemtemplate =[];
  

  public async mail_template(trans_id) {
     if(this.mailtemplate[trans_id] === undefined){
       const mail = await  API.get(`email/email/${trans_id}`);
      this.mailtemplate[trans_id] = mail.data;
      
    }
     
    return this.mailtemplate[trans_id];
  }

  public async system_template(trans_id) {
    if(this.systemtemplate[trans_id] === undefined){
      const mail = await  API.get(`email/system/${trans_id}`);
      this.systemtemplate[trans_id] = mail.data;
      
    }
    return this.systemtemplate[trans_id];
  }


}

// send mail to customer popup service

const subject = new Subject();
 
export const SeeNewletterService = {
   
  showPopup: (data: EmailModel) =>{
    
      getNewlettersEmail(data.id).then(res => {
        subject.next( {show: true, orders: res})  
      })
      
    
  } ,
  showOrderPopup: (data: SystemEmailModel) => {

    getSystemEmail(data.id).then(res => {
      subject.next( {show: true, orders: res})  
    })

  },
   
  
  hidePopup: () => subject.next({show: false, translator_id: [] }),
  getPopupState: () => subject.asObservable()
};


export async function getNewlettersEmail(trans_id) {
  
  return NewletterContent.instance.mail_template(trans_id); 
}

export async function getSystemEmail(trans_id) {
   return NewletterContent.instance.system_template(trans_id); 
}

export class NewletterServices {
  mailattchment = [];
  /**
   * get email template
   * @param trans_id
   * @returns {Promise<T>}
   */
  public static async getNewletterEmail(trans_id) {
    const res = await API.get(`email/email/${trans_id}`);
    return res.data;
  }
  
  public static async getNewletterOrderEmail(trans_id) {
    const res = await API.get(`email/email/${trans_id}`);
    return res.data;
  }

}
