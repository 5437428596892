import * as React from 'react';
import Lang from "../lang/Lang";

export const PleaseWait = (props?: any) => {
  return (
    <div className="col-md-12">
      <div className="alert alert-solid-brand alert-bold" role="alert">
        <div className="alert-text">{Lang.please_wait}</div>
      </div>
    </div>
  )
}