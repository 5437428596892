import * as React from 'react';
declare var window: any;

export class ErrorPage extends React.Component {
  render() {
    return(
        <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid" style={{backgroundImage: `url(${window.PUBLIC_URL}/admin/assets/media//error/bg4.jpg)`}}>
          <div className={'row'}>
            <div className="kt-error_container">
                <h1 className="kt-error_number">
                  404
                </h1>
                <p className="kt-error_title">
                  ERROR
                </p>
                <p className="kt-error_description">
                  Beklager!, den side, du leder efter, findes ikke.
                  Fortsæt til vores startside eller til dit betjeningspanel
                </p>
            </div>
          </div>
      </div>
    );
  }
}