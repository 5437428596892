// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".LeaveTypeSelector_container__3Oyxe {\n  width: 100%;\n}\n\n.LeaveTypeSelector_items_row__LxyqI {\n  display: flex;\n  align-items: flex-start;\n  justify-content: center;\n  width: 100%;\n  margin: 1rem 0;\n}\n\n.LeaveTypeSelector_checkbox__1S2qG {\n  height: 120px;\n  position: relative;\n}\n\n.LeaveTypeSelector_checkbox_label__T8uTd {\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  text-align: center;\n}\n\n.LeaveTypeSelector_radio_label__3xdSI {\n  font-family: \"Poppins\" !important;\n  padding-left: 30px;\n}\n.LeaveTypeSelector_radio_label__3xdSI::before, .LeaveTypeSelector_radio_label__3xdSI::after {\n  height: 17px;\n  width: 17px;\n  top: 4px;\n}", ""]);
// Exports
exports.locals = {
	"container": "LeaveTypeSelector_container__3Oyxe",
	"items_row": "LeaveTypeSelector_items_row__LxyqI",
	"checkbox": "LeaveTypeSelector_checkbox__1S2qG",
	"checkbox_label": "LeaveTypeSelector_checkbox_label__T8uTd",
	"radio_label": "LeaveTypeSelector_radio_label__3xdSI"
};
module.exports = exports;
