

export const WeLearnValidations = function () {

  $(document).ready(function () {

    $( "#create_we_learn_form" ).validate({
      // define validation rules
      rules: {
        title: {
          required: true
        },
        description: {
          required: true
        },
        video: {
          required: true,
        },
        video_thumbnail: {
          required: true,
        }
      }
    });

    $( "#update_we_learn_form" ).validate({
      // define validation rules
      rules: {
        title: {
          required: true
        },
        description: {
          required: true
        },
        video: {
          required: function () {
            return $("#hidden_video").val() == '';
          },
        },
        video_thumbnail: {
          required: function () {
            return $("#hidden_video_thumbnail").val() == '';
          },
        }
      }
    });

  });
}