import { OrderContactPerson } from "./OrderContactPerson";
import { CompanyModel } from "./Company";
import { DepartmentModel } from "./Department";
import { LanguageModel } from "./Language";
import { UserModel } from "./User";
import { OrderInvitation } from "./OrderInvitation";
import { TranslationTypeModel } from "./TranslationType";
import { OrderCommentModel } from "./OrderComment";
import { OrderCancelModel } from "./OrderCancel";
import { OrderFeedbackModel } from "./OrderFeedback";
import { PayoutModel } from "./Payout";
import { DepartmentUnitModel } from "./DepartmentUnit";
import { OrderEditModel } from "./EditRequest";
import { OrderConst } from "../orders/orders/Data";
import { CallLogModel } from "./CallLog";
import { CountryModel } from "./Country";
import { ClaimModel } from "./Claim";
import { MessageModel } from "./Message";
import { HolidayModel } from "./Holiday";
import { Icons } from "../common";

export interface ClientData {
  index: number;
  client_name: string;
  client_cpr: string | number;
  client_lat: string;
  client_long: string;
  id?: number;
  client_id?: number;
  order_id?: number;
}

export interface ClientSuggestion {
  client_index: number;
  suggestions: ClientData[];
}
export interface OrderModel {
  id?: number;
  order_no?: string;
  order_status?: number;
  is_paid?: number;
  customer_id?: number;
  translator_id?: number;
  translation_type_id: number;
  translator_gender?: string;
  order_date?: string;
  order_from?: string;
  order_to?: string;
  complete_order_from?: string;
  complete_order_to?: string;
  from_language?: number;
  to_language?: number;
  schedule_team_meeting?: boolean;
  video_meeting_link?: string;
  company_id: string;
  company_id_number: string | number;
  department_id: number | string;
  department_unit_id?: number | string;
  department_unit?: DepartmentUnitModel;
  project_id?: number | string;
  address: string;
  city?: string;
  zip_code?: string;
  fax?: string;
  mobile_no?: string; //
  email?: string; //
  meeting_address_same?: boolean;
  meeting_address?: string;
  addition_info?: string;
  meeting_city?: string;
  meeting_zip_code?: string;
  meeting_lat?: string;
  meeting_long?: string;
  doc_price?: number;
  fix_price?: number;
  total_distance?: number;
  travel_time?: number;
  document?: string; //
  tel_message?: string; //
  tel_mobile?: string; //
  clients: Array<ClientData>;
  employee_id?: string; //
  comment_for_translator?: string; //
  ean_number?: string | number; //
  status?: number;
  handle_by?: string;
  created_by?: UserModel;
  completed_by?: number;
  created_at?: string;
  completed_at?: string;
  is_second_person?: boolean;
  is_ever_assigned?: boolean;
  last_assigned_date?: string;
  skanderborg_acceptance_status?: "accepted" | "rejected" | null;
  skanderborg_rejection_message?: string | null;
  contact_persons?: Array<OrderContactPerson>;
  company?: CompanyModel;
  department?: DepartmentModel;
  language_from?: LanguageModel;
  language_to?: LanguageModel;
  departmentUnit?: DepartmentUnitModel;

  project?: any;
  createdBy?: UserModel;
  ModalData?: OrderModel;
  show?: boolean;
  translate_by?: UserModel;
  customer?: UserModel;
  lat?: string;
  lng?: string;
  billing_email?: string;
  billing_mobile_number?: string;
  office_note?: string;
  is_dumb?: number;
  is_talk_trans?: number;
  is_assign_other?: number;
  not_specific_translator_id?: number;
  specific_translator_id?: number;
  payment_modified?: string;
  specific_translator?: UserModel;
  not_specific_translator?: UserModel;
  country?: CountryModel;
  claim?: ClaimModel;
  rating?: number;
  message?: string;

  // custom virtual field
  order_complete_time?: string;
  time_difference?: string;
  order_day?: string;

  invitations?: Array<OrderInvitation>;
  sms_accept?: Array<OrderInvitation>;
  translation_type?: TranslationTypeModel;

  comments_count?: number;
  comments?: Array<OrderCommentModel>;
  order_files?: Array<any>;

  cancel?: OrderCancelModel;
  cancel_count?: number;

  customer_email?: MessageModel;
  call_log?: CallLogModel;
  feedback?: OrderFeedbackModel;
  payout?: PayoutModel;

  edit_request?: OrderEditModel;
  edit_request_count?: number;
  admin_user?: UserModel;
  icon?: string;

  invitations_count?: number;
  invitations_reject?: number;
  invitations_not_answer?: number;
  work_time?: number;
  platform?: string;
  flag_popup?: number;
  ref_id?: number;
  ref_source?: string;
  attention_status?: number;
  special_status?: number;
  special_status_update_user?: UserModel;
  compensation_agreed?: number;
  transport_fee_agreed?: number;
  fix_rates?: any;
  holiday_order?: HolidayModel;
}

export const OrderStatus = {
  NEW_ORDER: 1,
  INVITATION_SENT: 2,
  TRANS_ASSIGNED: 3,
  CANCELLED: 4,
  COMPLETED: 5,
  PAID: 6,
  UNDER_QA: 7,
};

export const NewOrderStatus = {
  COMPLETED: 0,
  WORKING: 3,
  DRAFTED: 2,
  PAID: 1,
  UNPAID: 0,
};

export const OrderType = {
  TELEPHONE: 1,
  PHYSICAL: 2,
  VIDEO: 3,
  SIGN_LANGUAGE: 4,
  PHONE_MESSAGE: 5,
  DOCUMENT: 6,
};

export const OrderTranslatorGender = {
  MALE: "1",
  FEMALE: "2",
  NOSIGNIFICANE: "0",
};

export const OrderProgress = {
  [OrderStatus.NEW_ORDER]: "25%",
  [OrderStatus.INVITATION_SENT]: "50%",
  [OrderStatus.TRANS_ASSIGNED]: "75%",
  [OrderStatus.CANCELLED]: "100%",
  [OrderStatus.COMPLETED]: "100%",
  [OrderStatus.PAID]: "100%",
};

export const OrderTypeIcons = {
  [OrderType.TELEPHONE]: Icons?.order_telephone ?? "fa fa-phone",
  [OrderType.PHYSICAL]: Icons?.order_physical ?? "fas fa-map-marker-alt",
  [OrderType.VIDEO]: Icons?.order_video ?? "fas fa-laptop",
  [OrderType.SIGN_LANGUAGE]: Icons?.order_sign ?? "fas fa-american-sign-language-interpreting",
  [OrderType.PHONE_MESSAGE]: Icons?.order_message ?? "fas fa-sms",
  [OrderType.DOCUMENT]: Icons?.order_document ?? "fas fa-file-word",
};

type TBA_OM_Shared =
  | "id"
  | "translator_id"
  | "translation_type_id"
  | "from_language"
  | "to_language"
  | "order_from"
  | "order_to"
  | "order_date"
  | "meeting_city"
  | "meeting_zip_code"
  | "order_no";
export interface TranslatorBusyAssignment extends Pick<OrderModel, TBA_OM_Shared> {
  type: string;
  from_iso: string;
  from_language_name: string;
  to_iso: string;
  to_language_name: string;
}
