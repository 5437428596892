import * as React from 'react';
import {SearchInput, SearchSelect, Icons, CreateLink, EditLink, actionButton, CONST,
  IState as CommonState, ShowRecords,
  ExportButton, getQueryStringParams} from "../../common";
import {default as Lang} from "../../common/lang/Lang"
import {RouteComponentProps} from "react-router";
import {UserModel, WeLearnModel} from "../../model";
import {ListBreadcrumb} from "./Data";
import {ListForm, ListHeader, ListingLayout} from "../../layout/Listing";
import _ from "lodash";
import LearnFilter from './filter/LearnFilter';
import {LearnService} from "../../services";
import {list_key} from "../../helpers/OrderHelper";

interface IState extends CommonState{
  UserData: Array<WeLearnModel>;
 }


export class WeLearn extends React.Component<RouteComponentProps, IState> {
  state: IState;
  records:React.RefObject<ShowRecords> = React.createRef();

  shouldComponentUpdate(nextProps: Readonly<RouteComponentProps>, nextState: Readonly<IState>, nextContext: any): boolean {
      
    return  this.state !== nextState;
     
 }

  showContent = (data: WeLearnModel, index: number) => {
    let cols = [];
    cols.push(...[
      <td key={index}>{this.records.current.checkbox(data.id)}</td>,
      this.records.current.createdTd("id", index),
      this.records.current.createdTd("title", data.title),
      this.records.current.createdTd("description", data.description),
      this.records.current.createdTd("video", () => {
        return <a href={data.video} target={'_blank'}>Video</a>
      }),
      
      this.records.current.createdTd("video_thumbnail", () => {
        return <img src={data.video_thumbnail} width="50px" height="50px"/>
      }),
      this.records.current.createdTd("status", () => {
        if(data.status === 1){
          return <span className={'badge badge-primary'}>{Lang.active}</span>
        }
        if(data.status === 2){
          return <span className={'badge badge-warning'}>{Lang.inactive}</span>
        }
      }),
      this.records.current.createdTd("created_at", data.created_at),
      this.records.current.createdTd("created_at", () => {
        return <EditLink scope={'we-learn.update'} to={`${LearnService.URLS.WE_LEARN.UPDATE}edit/${data.id}`}/>
      }),
    ]);
    cols = _.compact(cols);

    return (
      <tr key={list_key(data.id)}>{cols}</tr>
    );
  }

  filterRecords = (data?: any) => {
    if( this.records && this.records.current !== null){
      if(data !== undefined){
        this.records.current.setFormFields(data);
      }else {
        this.records.current.setFormFields();
      }
    }
  }




  rowActionButtons = (data: UserModel) => {
    return (
      <EditLink scope={'we-learn.update'} to={`${this.props.match.url}/edit/${data.id}`} />
    )
  }

  render() {
    const status = CONST.status;
    return (
        <ListingLayout breadcrumb={ListBreadcrumb}>
          <ListHeader title={'we_learn'} icon={Icons.question} >
            <ExportButton records={this.records} fileName={Lang.exportWeLearn}/>
            &nbsp;
            <CreateLink to={`${this.props.match.url}/create`} scope={'we-learn.store'} />
          </ListHeader>

          <ListForm
            form={(
              <LearnFilter  status={status} getResults={this.filterRecords} />
            )}
            table={(
              <ShowRecords
                url={"/learn/we-learn"}
                showRecords={this.showContent}
                ref={this.records}
                isNeedAction={true}
                actionButtons={actionButton({records: this.records, 'url': "learn/we-learn/bulk-action", "module": "we-learn"})}
                rowActions={this.rowActionButtons}
              />
            )}
          />
        </ListingLayout>
    )
  }
}
