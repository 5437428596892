import React, {useState, useEffect} from 'react';
import {Icons, LoaderType, RequestWaiting, SetWaitingArea, SwitchLoader} from "../common";
import {ActivityLogModel} from "../model";
import {DashboardService} from "../services";
import _ from 'lodash';
import Lang from "../common/lang/Lang";

const moment = require("moment");


export function ActivityLog() {

  const [log, setLog] = useState([{} as ActivityLogModel]);

  useEffect(() => {
    getRecentLog();
  }, []);


  function getRecentLog() {
    SwitchLoader(LoaderType.NONE);
    SetWaitingArea('log')
    DashboardService.recentActivityLog().then(res => {
      setLog(res)
    })
  }


  return (
    <div className="kt-portlet kt-portlet--height-fluid">
      <div className="kt-portlet__head">
        <div className="kt-portlet__head-label">
          <h3 className="kt-portlet__head-title">
            {Lang.recent_activities}
          </h3>
        </div>
        <div className="kt-portlet__head-toolbar">
          <button type="button" className="btn btn-clean btn-sm btn-icon btn-icon-md" onClick={getRecentLog}>
            <i className={Icons.refresh} title={Lang.refresh}  data-toggle="kt-tooltip" data-placement="top" data-original-title={Lang.refresh} />
          </button>
        </div>
      </div>
      <div className="kt-portlet__body">

        <div className="kt-timeline-v2">
          <div className="kt-timeline-v2__items  kt-padding-top-25 kt-padding-bottom-30">
            <RequestWaiting item={log} from={'log'}>
              {
                log.length > 0 && log.map((item: ActivityLogModel) => {
                  if(!_.isEmpty(item)){
                    return (
                      <div className="kt-timeline-v2__item" key={item.id}>
                        <span className="kt-timeline-v2__item-time">{moment(item.action_at).format('HH:mm')}</span>
                        <div className="kt-timeline-v2__item-cricle">
                          <i className="fa fa-genderless kt-font-success"/>
                        </div>
                        <div className="kt-timeline-v2__item-text  kt-padding-top-5">{item.message}</div>
                      </div>
                    )
                  }
                })
              }
            </RequestWaiting>
          </div>
        </div>
      </div>
    </div>
  )
}