import React, {useReducer} from 'react';
import {SystemEmailModel} from "../../../model/SystemEmail";

// all actions for assign translator
export enum ActionType {
  SHOW_POPUP = 'SHOW_POPUP',
  HIDE_POPUP = 'HIDE_POPUP',
  GET_ORDERS = 'GET_ORDERS',
  HANDLE_CHANGE = 'HANDLE_CHANGE',
}

export interface PopupState  {
  show: boolean;
  type: string;
  translator_id: number;
  orders: SystemEmailModel;
  to_approve: Array<number>;

}

export interface CAction {
  type: ActionType;
  payload?: PopupState
}

export const InitialState = {
  to_approve: []
} as PopupState;

// reducer for fix price popup

export function OrderPopupReducer <PopupState, CAction > (state, action) {
  switch (action.type) {
    // show popup and set order data
    case ActionType.SHOW_POPUP:
      return {...state, show: true, orders: action.payload.orders, type: action.payload.type};

    case ActionType.GET_ORDERS:
      return {...state, orders: action.payload, to_approve: []};

    // hide popup and blank old state
    case ActionType.HIDE_POPUP:
      return {...state, show: false};

      // handle change of mail drop-down
    case ActionType.HANDLE_CHANGE:
      if(action.payload.checked === true){
        return {...state, to_approve: [...state.to_approve, action.payload.value] };
      } else {
        return {...state, to_approve: state.to_approve.filter(function(item: number) {
            return item !== action.payload.value
          }) };
      }



    default:
      throw new Error();
  }
}