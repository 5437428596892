import Lang from "../../common/lang/Lang";
import {RoleModel} from "../../model/Role";
import {WeLearnModel} from "../../model";

export const urls = {
  users: "/learn/",
  list: "/learn/we-learn",
}

export const ListBreadcrumb = [
  {
    label: Lang.learn,
    url: urls.users
  },
  {
    label: Lang.we_learn,
    url: "#"
  }
];

export const CreateBreadcrumb = [
  {
    label: Lang.learn,
    url: urls.users
  },
  {
    label: Lang.we_learn,
    url: urls.list
  },
  {
    label: Lang.create,
    url: '#'
  }
];

export const EditBreadcrumb = [
  {
    label: Lang.learn,
    url: urls.users
  },
  {
    label: Lang.we_learn,
    url: urls.list
  },
  {
    label: Lang.edit,
    url: '#'
  }
];


export interface IState{
  form: WeLearnModel;
  id?: number;
}