import * as React from 'react';
import {Breadcrumb} from "../../common/page/Breadcrumb";
import Lang from "../../common/lang/Lang";
import {EditBreadcrumb} from "./Data";
import API from './../../common/AxoisClient';
import {showNotification} from "../../common/Common";
import {AdminUserTabs} from "./Tabs";
import { UserModel} from "../../model/User";
import {RouteComponentProps} from "react-router";
import {ChangePassword} from "../../common/page/ChangePassword";
declare var window: any;

interface IState{
  id: number;
  form: {
    permissions: Array<string>
  }
  user?: UserModel;
  scopes: Array<any>;
}

export class AdminUserChangePassword extends React.Component<RouteComponentProps, IState> {
  state: IState;

  constructor(props: any){
    super(props);

    this.state = {
      form: {
        permissions: []
      },
      id: props.match.params.id,
      scopes: [],
    }
   // AdminUserValidations();
  }

  componentDidMount() {

    API.get(`users/admin-users/${this.state.id}`).then((res: any) => {
      this.setState({
        form: { ...this.state.form, permissions: res.data.permission !== null ? res.data.permission.permissions.split(",") : []}
      })
    })

    API.get('permission/role/create').then((res: any) => {
      this.setState({
        scopes: res.data
      }, () => {
        window.KTApp.initTooltips();
      })
    });
  }

  handleScopes = (event: React.ChangeEvent<HTMLInputElement>, id: string) => {
    if(event.target.checked === true){
      this.setState(prevState => {
        return { form: { ...this.state.form, permissions: [...prevState.form.permissions, id]} }
      });
    } else {
      this.setState({ form: { ...this.state.form, permissions: this.state.form.permissions.filter((item: string) => {
            return item !== id
          })} });
    }
  }

  handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if($("#create_company").valid()) {
      API.put(`settings/company/${this.state.id}`, this.state.form).then((res) => {
        showNotification("success", Lang.company_update);
      })
    }
  }


  render() {
    return (
      <div>
        <Breadcrumb data={EditBreadcrumb}/>

        <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
          <div className="kt-portlet">
            <div className="kt-portlet__body">
              <div className="kt-grid  kt-wizard-v2 kt-wizard-v2--white" id="kt_wizard_v2" data-ktwizard-state="step-first">
                <AdminUserTabs active={'password'} user_id={this.state.id}/>
                <div className="kt-grid__item kt-grid__item--fluid kt-wizard-v2__wrapper">
                  <div className="kt-form" id="create_company"  style={{width: '100%'}}>
                    <div className="kt-wizard-v2__content" data-ktwizard-type="step-content" data-ktwizard-state="current">
                      <div className="kt-heading kt-heading--md">{Lang.change_password}</div>
                      <div className="kt-form__section kt-form__section--first">

                          <ChangePassword id={this.state.id}/>

                      </div>
                    </div>
                  </div>


                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    )
  }
}