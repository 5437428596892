
export const ProfileValidations = function () {

  $(document).ready(function () {

    $( "#updateProfile" ).validate({
      rules: {
        name: {
          required: true
        },
        mobile_no: {
          required: true
        }
      },

      invalidHandler: function(event, validator) {
        var alert = $('#kt_form_1_msg');
        alert.removeClass('kt--hide').show();
      },
    });
  });
}