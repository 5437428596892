
declare var window: any;
export const EmailTemplateValidations = function () {

  $(document).ready(function () {

    $( "#create_emailTemplate" ).validate({
      rules: {
        name: {
          required: true
        },
        type: {
          required: true
        },
        subject: {
          required: true
        },
      },

      invalidHandler: function(event, validator) {
        var alert = $('#kt_form_1_msg');
        alert.removeClass('kt--hide').show();
      },
    });
  });
}