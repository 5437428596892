import * as React from 'react';
import {default as Lang} from "../../common/lang/Lang"
import { ExportButton,  Icons, ActionLink, ShowRecords,
  IState as CommonState
} from "../../common";
import {RouteComponentProps, Switch} from "react-router";
import {actionButton} from "../../common/table/BlukActionOrderButtons";
import {CancelListBreadcrumb} from "./Data";
import {ListForm, ListHeader, ListingLayout} from "../../layout/Listing";
import {OrderModel, ClientData, OrderType,OrderTranslatorGender} from "../../model";
import {OrderInfo} from "./OrderInfo";
import _ from "lodash";
import {CancelOrderActions} from "./OrderActions";
import {OrderComments, HistoryPopup } from "./popup";
import {
  list_key, OrderHelper, order_time, order_to_language, SpecificUsergender,
  Usertranslategender
} from "../../helpers/OrderHelper";
import CancelOrderFilter from "./filter/CancelOrderFilter";
import {OrderCommentService} from "../../services";
import Files from "./Files";
import ActiveOrderFilter from "./filter/ActiveOrderFilter";
import { Tooltip } from '@material-ui/core';
import Modal from '../../layout/Modal/Modal';
import SkanderborgFeedback from './order-compnent/skanderborg-feedback/SkanderborgFeedback';
const moment = require("moment");
declare var window:any;

interface IState extends CommonState {
    ClaimData: Array<OrderModel>;
    ModalData?: OrderModel;
    show?: boolean;
    languages: Array<{value: string, label:string}>;
    companies: Array<{value: string, label:string}>;
}


export class CancelOrders extends React.Component<RouteComponentProps, IState> {
  state: IState;
  records:React.RefObject<ShowRecords> = React.createRef();


  shouldComponentUpdate(nextProps: Readonly<RouteComponentProps>, nextState: Readonly<IState>, nextContext: any): boolean {
    return  this.state !== nextState;       
 }
 
 TranslatorGenderNamecolor = (data) =>{
	 
	  let bold,fontsize,fcolor,maleiconcolor;
   bold = 'bold';
   fcolor = 'red';
   maleiconcolor = 'blue';
  fontsize = '15px';
  if(!_.isEmpty(data.translate_by)){
    if(data.translate_by.gender === OrderTranslatorGender.MALE){
      return <span  style={{color: maleiconcolor}}>{data.translate_by.name}</span>
    }  else if(data.translate_by.gender === OrderTranslatorGender.FEMALE){
      return <span style={{color: fcolor}}>{data.translate_by.name}</span>
    }
    
  }else{
	  return <span>{'N/A'}</span>
  }
	 
 }

  showContent = (data: OrderModel, index: number) => {
	 
    let cols = [];
    cols.push(...[
      // <td key={index}>{this.records.current.checkbox(data.id)}</td>,
      this.records.current.createdTd("order_no", () =>{
        return (
          <div>
            <button className={'btn btn-default'}  onClick={() => this.setState({ModalData: data, show: true})}  data-skin={'dark'} title={Lang.view_order} >
              {data.order_no}
            </button>
            <br/><br/>
            
            {data.translation_type.title}
            <br/><br/>
             {/* {data.claim !== null && data.claim[0] !== undefined &&
            data.claim[0].order_id !== '' ? <span className="badge badge-info">claimed</span> :''
          } */}
          {!_.isEmpty(data.cancel) && data.cancel.is_charge !== null &&  data.cancel.is_charge !== 0 &&
              <p><i className="fa fa-dollar-sign" aria-hidden="true" style={{color:'#299798', fontSize:15}}/></p>
          }
            </div> 
        )    
      }),
      this.records.current.createdTd("company_id", () => {
        const skanderborgIds = [700, 888, 1999, 2031, 2040, 2088, 2089, 2102, 2113];
        const vitolkerIds = [
          10, 19, 85, 106, 200, 294, 402, 440, 492, 857, 861, 869, 870, 871, 872, 881, 892, 896,
          914, 919, 925, 982, 983, 984, 992, 993, 994, 995, 997, 1092, 1093, 1396, 1398, 1881,
        ];
        const specialFeedbackIds = [...skanderborgIds, ...vitolkerIds];
        const SkanderborgFeedbackElement = () => {
          const [showModal, setShowModal] = React.useState<boolean>(false);
          return (
            data.skanderborg_acceptance_status !== null && (
              <>
                <Tooltip
                  title={
                    data.skanderborg_rejection_message ?? data.feedback?.message ?? "Godkendt"
                  }
                >
                  <span
                    style={{
                      color:
                        data.skanderborg_acceptance_status === "accepted" ? "#00B5AD" : "#ff5a5f",
                      fontWeight: "normal",
                    }}
                  >
                    {data.skanderborg_acceptance_status === "accepted" ? "Godkendt" : "Afvist"}{" "}
                    (Skanderborg)
                  </span>
                </Tooltip>
                <br />
                <span
                  style={{ color: "#0000EE", textDecoration: "underline", cursor: "pointer" }}
                  onClick={() => setShowModal(true)}
                >
                  Behandle
                </span>
                <Modal isOpen={showModal} onClose={() => setShowModal(false)}>
                  <SkanderborgFeedback orderId={data.id} />
                </Modal>
              </>
            )
          );
        };
        return (
          <>
            <div>
              {OrderHelper.getCompany(data)} / {OrderHelper.getDept(data)} / {OrderHelper.getDeptUnit(data)}
            </div>
            {specialFeedbackIds.includes(data.company?.id) && <SkanderborgFeedbackElement />}
          </>
        )
      }),
      this.records.current.createdTd("translator_id", this.TranslatorGenderNamecolor(data)),
      this.records.current.createdTd("contact_person", () => {
        return data.contact_persons.map(item => {
          return (
              <React.Fragment>
                {/*<p> Kontaktperson: {item.name} </p>*/}
                  <p>{item.name} </p>
                {!_.isEmpty(item.email) && <p>Email: {item.email}</p>}
                {!_.isEmpty(item.phone_no) && <p>Telefon : {item.phone_no}</p>}
                <hr/>
              </React.Fragment>
          )
        })

      }),
      this.records.current.createdTd("language", () => {
        return (
          <div style={{width:'200px'}}>
            {!_.isEmpty(data.language_from) &&
              <React.Fragment>
                <img alt={data.language_from.country.name} src={window.PUBLIC_URL +'/admin/assets/flags/'+_.lowerCase(data.language_from.country.iso) +'.png'} className="img-flag" />&nbsp;{ data.language_from.language_name}<span className="exchangeIcon">
                <i className="fas fa-exchange-alt"/></span>
              </React.Fragment>
            }

           {(data.is_dumb === 0 && data.language_to !== null) &&
           <img alt={data.language_to.country.name} src={window.PUBLIC_URL +'/admin/assets/flags/'+_.lowerCase(data.language_to.country.iso) +'.png'} className="img-flag" />
           }
           &nbsp;{order_to_language(data)}
           <br/><br/>
          {data.clients !== null  &&
           <span>{Lang.client_name} :&nbsp;{
               !_.isEmpty(data.clients)? data.clients.map((client: ClientData, index: number) => {
                   return (
                       <React.Fragment key={index}>
                           {index + 1}) {client.client_name === ''? '----' : client.client_name}{index === data.clients.length - 1? '' : ' '}
                       </React.Fragment>
                   )
               }) : '----'
           }</span>
           }
          </div>
        );
      }),
       
      this.records.current.createdTd("order_date", moment(data.order_date).format('DD-MM-YYYY')),
      this.records.current.createdTd("order_time", order_time(data)),
      this.records.current.createdTd("comment", () => {
        if(!_.isEmpty(data.cancel) ){
          return (
            <React.Fragment>
              <p>Hvordan : {data.cancel.cancel_how}</p>
              <p>Hvornår : {data.cancel.cancel_when}</p>
              <p>Grund : {data.cancel.cancel_message}</p>
              {
                !_.isEmpty(data.cancel.reason) &&  <p>Reason : {data.cancel.reason}</p>
              }
            </React.Fragment>
          )
        } else {
          return null;
        }

      }),

      this.records.current.createdTd("action", () => {
        return (
          <div>
            <ActionLink scope={'orders.index'}  to={`/orders/order/info/${data.id}`}  data-skin={'dark'} title={Lang.view_order} >
              <i className={`kt-nav__link-icon ${Icons.view}`} />
            </ActionLink>
            <ActionLink scope={'orders.index'} to={'#'}    onClick={() =>OrderCommentService.showPopup(data)}  >
                <i className="la la-comments"/> <span className="commentCountBox">{data.comments_count}</span>
            </ActionLink>
            { data.translation_type_id === OrderType.DOCUMENT && <Files files={data.order_files} /> }
            <CancelOrderActions order={data} refresh={this.filterRecords} type={'order'}/>
          </div>
          )
        }),
      ]);
      cols = _.compact(cols);
      return (
      <tr key={list_key(data.id)}>{cols}</tr>
    );
  }

  filterRecords = (data?: any) => {
    if( this.records && this.records.current !== null){
      if(data !== undefined){
        this.records.current.setFormFields(data);
      }else {
        this.records.current.setFormFields();
      }
    }
  }

  render() {

    return (
      <ListingLayout breadcrumb={CancelListBreadcrumb}>
         <ListHeader title={'cancel_order_title'} icon={Icons.order} >
       <ExportButton records={this.records} fileName={Lang.exportCancelOrders}/>
            &nbsp;
          </ListHeader>
          <ListForm
            form={(
              <CancelOrderFilter  getResults={this.filterRecords} languages={this.state !== null ? this.state.languages: []} companies={this.state !== null ? this.state.companies: []} />
            )}
            table={(
              <ShowRecords
                url={"orders/cancel-orders"}
                showRecords={this.showContent}
                ref={this.records}
                isNeedAction={false}
                showCheckbox={false}
                actionButtons={actionButton({records: this.records, 'url': "orders/orders/bulk-action"})}
              />
            )}
          />
          
        {this.state !== null &&
        <OrderInfo
            show={this.state.show}
            onHide={() => this.setState({show: false})}
            orderinfo={this.state.ModalData}/>
        }



        <OrderComments/>
        <HistoryPopup />
      </ListingLayout>
     
    )
  }
}