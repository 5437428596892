import * as React from 'react';
import Lang from "../../common/lang/Lang";
import {ChangePassword} from "../../common/page/ChangePassword";
import {RouteComponentProps} from "react-router";
import {Breadcrumb} from "../../common/page/Breadcrumb";
import {ChangePwdBreadcrumb} from "./Data";

export class CustomerChangePassword extends React.Component <RouteComponentProps>{

  render() {
    const {id}: any = this.props.match.params;
    return(
      <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
        <Breadcrumb data={ChangePwdBreadcrumb}/>
        <div className="kt-portlet kt-portlet--mobile">
          <div className="row">
            <div className="col-xl-12">
              <div className="kt-portlet">
                <div className="kt-portlet__head">
                  <div className="kt-portlet__head-label">
                    <h3 className="kt-portlet__head-title">{Lang.change_password}</h3>
                  </div>
                </div><br/>
                <ChangePassword id={id}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}