import * as React from 'react';
import {EditBreadcrumb, IState} from "./Data";
import Lang from "../../common/lang/Lang";
import {RouteComponentProps} from "react-router";
import { WeLearnModel} from "../../model";
import {WeLearnValidations} from "./Validations";
import {CreateLayout} from "../../layout/CreateLayout";
import {CommonSubmitButton} from "../../common";
import {LearnService} from "../../services";

export class WeLearnEdit extends React.Component<RouteComponentProps<any>, IState> {
  state: IState;

  constructor(props:any){
    super(props);
    this.state = {
      form: {} as WeLearnModel,
      id: props.match.params.id
    };
    WeLearnValidations();
  }

  componentDidMount() {
    LearnService.view(this.state.id).then(res => {
      this.setState({form: {...res, hidden_video: res.video, video: '', hidden_video_thumbnail: res.video_thumbnail, video_thumbnail: ''}})
    })
  }

  handleChange = (event: React.ChangeEvent<HTMLElement>) => {
    const target : any = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.type === 'file' ? target.files[0] :  target.value;
    const name = target.name;
    this.setState({ form: { ...this.state.form, [name]: value} });
  }


  handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if($("#update_we_learn_form").valid()){
      LearnService.update(this.state.id, this.state.form).then(res => {
        this.props.history.push(LearnService.URLS.WE_LEARN.LIST);
      })
    }
  }

  render() {
    return (
         <CreateLayout breadcrumb={EditBreadcrumb} title={'edit_we_learn'}>
          <form className="kt-form kt-form--label-right" id={'update_we_learn_form'} onSubmit={this.handleSubmit}>
            <div className="kt-portlet__body text-center">
              <div className={'col-md-8'} style={{paddingTop:20}}>
                <div className="form-group row">
                  <label className={'col-2 col-form-label'}>{Lang.title} <span className={'required'}>*</span></label>
                  <div className="col-10">
                    <input type={'text'} onChange={this.handleChange} value={this.state.form.title || ''} name={'title'} className="form-control" placeholder={Lang.enter_title}/>
                  </div>
                </div>

                <div className="form-group row">
                  <label className={'col-2 col-form-label'}>{Lang.description} <span className={'required'}>*</span></label>
                  <div className="col-10">
                    <textarea onChange={this.handleChange} value={this.state.form.description} name={'description'} className="form-control" placeholder={Lang.enter_description} />
                  </div>
                </div>
                <div className="form-group row">
                  <label className={'col-2 col-form-label'}>{Lang.video}</label>
                  <div className="col-10">
                    <div className="custom-file">
                      <input type={'file'} onChange={this.handleChange}  name={'video'} className="custom-file-input"/>
                      <label className="custom-file-label" htmlFor="customFile"/>
                    </div>
                    <div className={'row'} style={{marginTop:10, marginLeft:0}}>
                      <input type={'hidden'} defaultValue={this.state.form.hidden_video} id={'hidden_video'}/>
                      <a className={'btn btn-primary'} href={this.state.form.hidden_video} target={'_blank'}>{Lang.view_video}</a>
                    </div>
                  </div>
                </div>


                <div className="form-group row">
                  <label className={'col-2 col-form-label'}>{Lang.video_thumbnail} </label>
                  <div className="col-10">
                    <div className="custom-file">
                      <input type={'file'} onChange={this.handleChange}  name={'video_thumbnail'} className="custom-file-input" />
                      <label className="custom-file-label" htmlFor="customFile"/>
                    </div>
                    <div className={'row'} style={{marginTop:10, marginLeft:0}}>
                      <input type={'hidden'} defaultValue={this.state.form.hidden_video_thumbnail} id={'hidden_video_thumbnail'}/>
                      <img src={this.state.form.hidden_video_thumbnail} width="50%" height="50%"/>
                    </div>
                  </div>
                </div>



              </div>
            </div>
            <CommonSubmitButton/>
          </form>
        </CreateLayout>
    )
  }
}