

export const AdminUserValidations = function () {

  $(document).ready(function () {

    $( "#admin_user_form" ).validate({
      // define validation rules
      rules: {
        role_id: {
          required: true
        },
        name: {
          required: true
        },
        email: {
          required: true,
          email:true
        },
        password: {
          required: true
        },
        cn_password: {
          required: true,
          equalTo: "#password"
        },
        mobile_no: {
          required: true
        }
      },
      invalidHandler: function(event, validator) {
        var alert = $('#kt_form_1_msg');
        alert.removeClass('kt--hide').show();
      },
    });
  });
}