import Lang from "../../common/lang/Lang";

export const urls = {
  home: "/email/",
  list: "/email/System Email",
}

export const ListBreadcrumb = [
  {
    label: Lang.newletter,
    url: urls.home
  },
  {
    label: Lang.system_email,
    url: "#"
  }
];

export const CreateBreadcrumb = [
  {
    label: Lang.email,
    url: urls.home
  },
  {
    label: Lang.email,
    url: urls.list
  },
  {
    label: Lang.create,
    url: '#'
  }
];

export const EditBreadcrumb = [
  {
    label: Lang.email,
    url: urls.home
  },
  {
    label: Lang.email,
    url: urls.list
  },
  {
    label: Lang.edit,
    url: '#'
  }
];