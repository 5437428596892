import * as React from 'react';
import {SearchInput, SearchSelect} from "../../common/form/SearchInput";
import {IState as CommonState} from "../../common/State";
import {ShowRecords} from "../../common/ShowRecords";
import {CONST, ExportButton, getQueryStringParams} from "../../common/Common";
import {Icons} from "../../common/Icon";
import {actionButton} from "../../common/table/BlukActionButtons";
import {RouteComponentProps} from "react-router";
import {RoleModel} from "../../model/Role";
import {ListBreadcrumb} from './Data';
import {CreateLink, EditLink} from "../../common/page/CreateButton";
import {ListForm, ListHeader, ListingLayout} from "../../layout/Listing";
import RoleFilter from './filter/RoleFilter';
import Lang from "../../common/lang/Lang";


interface IState extends CommonState{
  UserData: Array<RoleModel>;
  }


export class RolesListing extends React.Component<RouteComponentProps, IState> {
  state: IState;
  records:React.RefObject<ShowRecords> = React.createRef();

  shouldComponentUpdate(nextProps: Readonly<RouteComponentProps>, nextState: Readonly<IState>, nextContext: any): boolean {
      
    return  this.state !== nextState;
     
 }

  filterRecords = (data?: any) => {
    if( this.records && this.records.current !== null){
      if(data !== undefined){
        this.records.current.setFormFields(data);
      }else {
        this.records.current.setFormFields();
      }
    }
  }

  

  rowActionButtons = (data: RoleModel) => {
    return (
        <td key={Math.random()}>
          <EditLink scope={'role.update'} to={`${this.props.match.url}/edit/${data.id}`} />
        </td>
    )
  }

  render() {
    const status = CONST.status;
    return (
        <ListingLayout breadcrumb={ListBreadcrumb}>
          <ListHeader title={'role'} icon={Icons.users} >
            <ExportButton records={this.records} fileName={Lang.exportRole}/>
            &nbsp;
            <CreateLink to={`${this.props.match.url}/create`} scope={'role.store'} />
          </ListHeader>

          <ListForm
            form={(
              <RoleFilter  status={status} getResults={this.filterRecords} />
          )}
            table={(
              <ShowRecords
                url={"permission/role"}
                ref={this.records}
                isNeedAction={true}
                showCheckbox={true}
                rowActions={this.rowActionButtons}
                actionButtons={actionButton({records: this.records, 'url': "permission/role/bulk-action", "module": "role"})}
              />
          )}
          />
        </ListingLayout>
    )
  }
}