// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".CreateOrder_details_section__v_804 {\n  display: grid;\n  grid-template-columns: 1fr 1fr;\n  width: 100%;\n  margin: 1rem 0 2rem;\n}\n\n.CreateOrder_radio_outer_container__2I3X_ {\n  display: flex;\n  flex-direction: column;\n}\n\n.CreateOrder_radio_inner_container__3XFai {\n  display: flex;\n}\n.CreateOrder_radio_inner_container__3XFai > * {\n  margin-right: 1rem;\n  margin-bottom: -1rem;\n}\n\n.CreateOrder_label_input_container__33rHE {\n  display: flex;\n  align-items: center;\n}\n.CreateOrder_label_input_container__33rHE > * {\n  margin: 0.3rem;\n}\n\n.CreateOrder_trans_selector__3cD6K {\n  width: 50%;\n}", ""]);
// Exports
exports.locals = {
	"details_section": "CreateOrder_details_section__v_804",
	"radio_outer_container": "CreateOrder_radio_outer_container__2I3X_",
	"radio_inner_container": "CreateOrder_radio_inner_container__3XFai",
	"label_input_container": "CreateOrder_label_input_container__33rHE",
	"trans_selector": "CreateOrder_trans_selector__3cD6K"
};
module.exports = exports;
