import * as React from 'react';
import {SearchInput, SearchSelect, Icons, CreateLink, EditLink, actionButton, CONST,
  IState as CommonState, ShowRecords,
  ExportButton, getQueryStringParams} from "../../common";
import {default as Lang} from "../../common/lang/Lang"
import {RouteComponentProps} from "react-router";
import {QuizModel, QuizTypeTitle, UserModel} from "../../model";
import {ListBreadcrumb} from "./Data";
import {ListForm, ListHeader, ListingLayout} from "../../layout/Listing";
import _ from "lodash";
import {LearnService, QuizService} from "../../services";
import OnlineFilter from './filter/OnlineFilter';
import {list_key} from "../../helpers/OrderHelper";

interface IState extends CommonState{
  UserData: Array<QuizModel>;
   total: number
}


export class OnlineTest extends React.Component<RouteComponentProps, IState> {
  state: IState;
  records:React.RefObject<ShowRecords> = React.createRef();

  shouldComponentUpdate(nextProps: Readonly<RouteComponentProps>, nextState: Readonly<IState>, nextContext: any): boolean {
      
    return  this.state !== nextState;
     
 }

  showContent = (data: QuizModel, index: number) => {
    let cols = [];
    cols.push(...[
      <td key={index}>{this.records.current.checkbox(data.id)}</td>,
      this.records.current.createdTd("id", index),
      this.records.current.createdTd("quiz_type", QuizTypeTitle[data.quiz_type]),
      this.records.current.createdTd("title", () => {
        return (
          <div>
            <p>{data.title}</p>
            {(data.image !== null) && <img src={data.image} height={100} width={200} />}
          </div>
        )
      }),
      this.records.current.createdTd("description", data.description),
      this.records.current.createdTd("total_marks", data.total_marks),
      this.records.current.createdTd("min_marks", data.min_marks),
      this.records.current.createdTd("time", data.time),
      this.records.current.createdTd("status", () => {
        if(data.status === 1){
          return <span className={'badge badge-primary'}>{Lang.active}</span>
        }
        if(data.status === 2){
          return <span className={'badge badge-warning'}>{Lang.inactive}</span>
        }
      }),
      this.records.current.createdTd("created_at", data.created_at),
      this.records.current.createdTd("created_at", () => {
        return <EditLink scope={'online-test.update'} to={`/learn/online-test/edit/${data.id}`}/>
      }),
    ]);
    cols = _.compact(cols);

    return (
      <tr key={list_key(data.id)}>{cols}</tr>
    );
  }

  filterRecords = (data?: any) => {
    if( this.records && this.records.current !== null){
      if(data !== undefined){
        this.records.current.setFormFields(data);
      }else {
        this.records.current.setFormFields();
      }
    }
  }

 
  rowActionButtons = (data: UserModel) => {
    return (
      <EditLink scope={'admin-users.update'} to={`${this.props.match.url}/edit/${data.id}`} />
    )
  }

  render() {
    const status = CONST.status;
    return (
        <ListingLayout breadcrumb={ListBreadcrumb}>
          <ListHeader title={'online_test'} icon={Icons.question} >
            <ExportButton records={this.records} fileName={Lang.exportOnlineTest}/>
            &nbsp;
            { 
          this.state !== null && this.state.total === 0 && <CreateLink to={`${this.props.match.url}/create`} scope={'online-test.store'} /> }

          </ListHeader>

          <ListForm
            form={(
              <OnlineFilter status={status} getResults={this.filterRecords} />
            )}
            table={(
              <ShowRecords
                url={'learn/online-test'}
                showRecords={this.showContent}
                ref={this.records}
                isNeedAction={true}
                actionButtons={actionButton({records: this.records, 'url': `learn/online-test/bulk-action`, "module": "online-test"})}
                rowActions={this.rowActionButtons}
                callBack={(data) => {
                   this.setState({total: data.meta.total})
                }}
              />
            )}
          />
        </ListingLayout>
    )
  }
}
