import * as React from "react";
import { default as Lang } from "../../common/lang/Lang";
import { RouteComponentProps } from "react-router";
import {
  ExportButton,
  Icons,
  ActionLink,
  ShowRecords,
  IState as CommonState,
  Select,
  EditLink,
  showNotification,
  base_url,
} from "../../common";
import { copyCodeToClipboard } from "../../helpers/OrderHelper";
import { actionButton } from "../../common/table/BlukActionOrderButtons";
import { CompleteListBreadcrumb, OrderConst } from "./Data";
import { ListForm, ListHeader, ListingLayout } from "../../layout/Listing";
import {
  ClientData,
  NewOrderStatus,
  OrderModel,
  OrderStatus,
  OrderTranslatorGender,
  OrderType,
} from "../../model";
import { OrderInfo } from "./OrderInfo";
import _ from "lodash";
import API from "../../common/AxoisClient";
import { CompleteOrderActions } from "./OrderActions";
import {
  OrderComments,
  HistoryPopup,
  FixPrice,
  Calllog,
  Telmessage,
  OrderFeedbackPopup,
  OrderFeedbackEditPopup,
} from "./popup";
import {
  order_time,
  order_to_language,
  OrderHelper,
  complete_order_time,
  SpecificUsergender,
  Usertranslategender,
  SpecificTranslatorNamecolor,
  list_key,
  chk_late_order,
} from "../../helpers/OrderHelper";
import {
  NotificationListNewService,
  OrderCommentService,
  OrderfeedbackService,
} from "../../services";
import { Link } from "react-router-dom";
import CompleteOrderFilter from "./filter/CompleteOrderFilter";
import { Stars } from "../../common/Stars";
import Files from "./Files";
import Swal from "sweetalert2";
import TravelDistance from "./order-compnent/TravelDistance";
import { Tooltip } from "@material-ui/core";
import SkanderborgFeedback from "./order-compnent/skanderborg-feedback/SkanderborgFeedback";
import Modal from "../../layout/Modal/Modal";
let moment = require("moment");

declare var window: any;

interface IState extends CommonState {
  ClaimData: Array<OrderModel>;
  languages: Array<{ value: string; label: string }>;
  companies: Array<{ value: string; label: string }>;
  ModalData?: OrderModel;
  show?: boolean;
  display_travel_time?: number;
  display_travel_distance?: number;
  display_order_id?: number;
}

export class CompleteOrders extends React.Component<RouteComponentProps, IState> {
  state: IState;
  records: React.RefObject<ShowRecords> = React.createRef();

  changeStatus = (event, order) => {
    API.post(`orders/order-completeorder-statusupdate`, {
      id: order,
      status: event.target.value,
    }).then((response) => {
      this.records.current.fetchData();
      NotificationListNewService.notificationlistdata();
    });
  };

  shouldComponentUpdate(
    nextProps: Readonly<RouteComponentProps>,
    nextState: Readonly<IState>,
    nextContext: any
  ): boolean {
    return this.state !== nextState;
  }

  Usergender = (data: OrderModel) => {
    let bold, fontsize, fcolor;
    bold = "bold";
    fcolor = "red";
    fontsize = "15px";
    /*if(data.translator_gender === OrderTranslatorGender.NOSIGNIFICANE){
   return <span className={'label'} style={{fontWeight: bold}}>{Lang.no_significance}</span>
  } else*/ if (data.translator_gender === OrderTranslatorGender.MALE) {
      return (
        <Link to={"#"}>
          <i className={Icons.male} style={{ fontSize: fontsize }} />{" "}
        </Link>
      );
    } else if (data.translator_gender === OrderTranslatorGender.FEMALE) {
      return (
        <Link to={"#"}>
          <i className={Icons.female} style={{ color: fcolor, fontSize: fontsize }} />
        </Link>
      );
    }
  };

  TranslatorGenderNamecolor = (data) => {
    let bold, fontsize, fcolor, maleiconcolor;
    bold = "bold";
    fcolor = "red";
    maleiconcolor = "blue";
    fontsize = "15px";
    if (!_.isEmpty(data.translate_by)) {
      if (data.translate_by.gender === OrderTranslatorGender.MALE) {
        return <span style={{ color: maleiconcolor }}>{data.translate_by.name}</span>;
      } else if (data.translate_by.gender === OrderTranslatorGender.FEMALE) {
        return <span style={{ color: fcolor }}>{data.translate_by.name}</span>;
      }
    } else {
      return <span>{"N/A"}</span>;
    }
  };

  showContent = (data: OrderModel, index: number) => {
    let cols = [];
    cols.push(
      ...[
        this.records.current.createdTd("id", index),
        this.records.current.createdTd("order_no", () => {
          return (
            <div>
              <button
                className={"btn btn-default"}
                onClick={() => this.setState({ ModalData: data, show: true })}
                data-skin={"dark"}
                title={Lang.view_order}
              >
                {data.order_no}
              </button>
              <br />
              <br />
              {/* Commented 14-Apr-2020 VTS-754
            { data.status === OrderStatus.PAID &&
                <>
                  <button type={'button'} className={'btn btn-success'}>{'Aflønnet'}</button>
                  <br/>
                  {moment(data.payment_modified).format('DD-MM-YYYY')}

                </>
            }
           
            { data.status === OrderStatus.COMPLETED &&
               <> <button type={'button'} className={'btn btn-warning'}>{'Ulønnet'}</button>
               <br/><br/>
               </>
            }*/}
              {data.is_paid === NewOrderStatus.PAID && (
                <>
                  <button
                    type={"button"}
                    onClick={(e) => this.change_paid_unpaid_status(data.id, "unpaid")}
                    className={"btn btn-success"}
                  >
                    {"Aflønnet"}
                  </button>
                  <br />
                  {moment(data.payment_modified).format("DD-MM-YYYY")}
                </>
              )}
              {(data.is_paid === NewOrderStatus.UNPAID || data.is_paid === null) && (
                <>
                  <button
                    type={"button"}
                    onClick={(e) => this.change_paid_unpaid_status(data.id, "paid")}
                    className={"btn btn-danger"}
                  >
                    {"Ulønnet"}
                  </button>
                  <br />
                  <br />
                </>
              )}

              {data.claim !== null && data.claim[0] !== undefined && (
                <>
                  {data.claim[0].order_id !== "" ? (
                    <span className="badge badge-info">claimed</span>
                  ) : (
                    ""
                  )}
                  <br />
                  <br />
                </>
              )}

              {/*{Usertranslategender(data)}&nbsp;
          {data.translation_type.title}*/}
              {data.status === OrderStatus.CANCELLED && (
                <>
                  <img src="/admin/assets/files/cancelled.png" />
                </>
              )}

              {data.feedback !== null && data.feedback.rating > 0 && (
                <>
                  <br />
                  <br />
                  {data.feedback.message == null && (
                    <>
                      <Stars
                        value={data.feedback.rating}
                        onClick={() => OrderfeedbackService.showPopup(data)}
                      />
                    </>
                  )}
                  {data.feedback.message && (
                    <>
                      <div className="star-content">
                        <div className="star-overlay"></div>
                        <Stars value={data.feedback.rating} />
                        <br />
                      </div>
                      <button
                        className={"btn btn-success"}
                        onClick={() => OrderfeedbackService.showPopup(data)}
                        type={"button"}
                        title={Lang.view_order}
                      >
                        See Feedback
                      </button>
                    </>
                  )}
                </>
              )}
            </div>
          );
        }),
        this.records.current.createdTd("language", () => {
          let color;
          color = "black";
          return (
            <div style={{ width: "200px" }}>
              <img
                src={
                  window.PUBLIC_URL +
                  "/admin/assets/flags/" +
                  _.lowerCase(data.language_from !== null ? data.language_from.country.iso : "--") +
                  ".png"
                }
                className="img-flag"
              />
              &nbsp;{data.language_from !== null ? data.language_from.language_name : "--"}
              <span className="exchangeIcon">
                <i className="fas fa-exchange-alt"></i>
              </span>
              {data.is_dumb === 0 && data.language_to !== null && (
                <img
                  src={
                    window.PUBLIC_URL +
                    "/admin/assets/flags/" +
                    _.lowerCase(
                      data.language_from !== null ? data.language_to.country.iso : "---"
                    ) +
                    ".png"
                  }
                  className="img-flag"
                />
              )}
              &nbsp;{order_to_language(data)}
              <br />
              <span>
                {Lang.client_name} :&nbsp;
                {!_.isEmpty(data.clients)
                  ? data.clients.map((client: ClientData, index: number) => {
                      return (
                        <React.Fragment key={index}>
                          {index + 1}) {client.client_name === "" ? "----" : client.client_name}
                          {index === data.clients.length - 1 ? "" : " "}
                        </React.Fragment>
                      );
                    })
                  : "----"}
              </span>
            </div>
          );
        }),
        this.records.current.createdTd("translator_id", this.TranslatorGenderNamecolor(data)),
        this.records.current.createdTd("status", () => {
          let $rowcolor,
            $selectwidth,
            $fntcolor = "";
          /* //var $status = data.status;*/

          /*if (data.status === OrderStatus.COMPLETED) {
          $rowcolor = '#f4e542';
          $fntcolor = 'white'; 
        } else if (data.status === OrderStatus.PAID) {
          $rowcolor = '#008000';
          $fntcolor = ' white'; 
        }*/
          if (data.order_status === NewOrderStatus.COMPLETED) {
            $rowcolor = "#f4e542";
            $fntcolor = "white";
          } else if (data.order_status === NewOrderStatus.WORKING) {
            $rowcolor = "transparent";
            $fntcolor = "white";
          } else if (data.order_status === NewOrderStatus.DRAFTED) {
            $rowcolor = "transparent";
            $fntcolor = "white";
          } else if (data.order_status === NewOrderStatus.PAID) {
            $rowcolor = "transparent";
            $fntcolor = " white";
          }
          const drop_down = () => {
            return (
              <Select
                value={data.order_status || ""}
                onChange={(e) => this.changeStatus(e, data.id)}
              >
                <option value={NewOrderStatus.COMPLETED}>{Lang.order_status_completed} </option>
                <option value={NewOrderStatus.WORKING}>{Lang.order_status_working} </option>
                <option value={NewOrderStatus.DRAFTED}>{Lang.order_status_drafted} </option>
                <option value={NewOrderStatus.PAID}>{Lang.order_status_paid} </option>
              </Select>
            );
          };
          const skanderborgIds = [700, 888, 1999, 2031, 2040, 2088, 2089, 2102, 2113];
          const vitolkerIds = [
            10, 19, 85, 106, 200, 294, 402, 440, 492, 857, 861, 869, 870, 871, 872, 881, 892, 896,
            914, 919, 925, 982, 983, 984, 992, 993, 994, 995, 997, 1092, 1093, 1396, 1398, 1881,
          ];
          const specialFeedbackIds = [...skanderborgIds, ...vitolkerIds];
          const SkanderborgFeedbackElement = () => {
            const [showModal, setShowModal] = React.useState<boolean>(false);
            return (
              data.skanderborg_acceptance_status !== null && (
                <>
                  <Tooltip
                    title={
                      data.skanderborg_rejection_message ?? data.feedback?.message ?? "Godkendt"
                    }
                  >
                    <span
                      style={{
                        color:
                          data.skanderborg_acceptance_status === "accepted" ? "#00B5AD" : "#ff5a5f",
                        fontWeight: "normal",
                      }}
                    >
                      {data.skanderborg_acceptance_status === "accepted" ? "Godkendt" : "Afvist"}{" "}
                      (Skanderborg)
                    </span>
                  </Tooltip>
                  <br />
                  <span
                    style={{ color: "#0000EE", textDecoration: "underline", cursor: "pointer" }}
                    onClick={() => setShowModal(true)}
                  >
                    Behandle
                  </span>
                  <Modal isOpen={showModal} onClose={() => setShowModal(false)}>
                    <SkanderborgFeedback orderId={data.id} />
                  </Modal>
                </>
              )
            );
          };

          return (
            <div style={{ backgroundColor: $rowcolor, color: $fntcolor }} className={"widthDown"}>
              {drop_down()}
              {specialFeedbackIds.includes(data.company?.id) && <SkanderborgFeedbackElement />}
            </div>
          );
        }),
        this.records.current.createdTd("order_date", () => {
          let date = moment(data.order_date, ' "YYYY-MM-DD HH:mm:ss"');
          return (
            <>
              {moment(data.order_date).format("DD-MM-YYYY") + " - " + date.format("dddd")}
              {data.holiday_order && (
                <>
                  <br />
                  <i className={`kt-nav__link-icon ${Icons.holiday}`} title="Holiday Order" />
                </>
              )}
            </>
          );
        }),
        this.records.current.createdTd("order_time", () => {
          return order_time(data);
        }),

        this.records.current.createdTd("complete_order_time", () => {
          return (
            <>
              {complete_order_time(data)}
              <br />
              {chk_late_order(data)}
            </>
          );
        }),

        this.records.current.createdTd(
          "order_complete_date",
          !_.isEmpty(data.payout) ? moment(data.payout.completed_at).format("DD-MM-YYYY") : "-"
        ),

        this.records.current.createdTd("contact_person", () => {
          return (
            <>
              {data.address} <br />
              <>
                {!_.isEmpty(data.contact_persons) &&
                  data.contact_persons.map((item, index) => {
                    return (
                      <>
                        <span key={index}>
                          {" "}
                          {item.name} <br /> {item.phone_no}{" "}
                        </span>
                        <br />
                        {index === 0 && data.contact_persons.length > 1 && (
                          <span>
                            -------------
                            <br />
                          </span>
                        )}
                      </>
                    );
                  })}
              </>
              {/*<ol className={'list-none'}>
                  { ! _.isEmpty(data.contact_persons) && data.contact_persons.map((item, index) => {
                   return (
                     <>
                      <li key={index}> {item.name} | {item.phone_no} </li>
                     </>
                   )
                  })
                }
                </ol>*/}
            </>
          );
        }),

        this.records.current.createdTd("company_id", () => {
          if (!_.isEmpty(data.company) && data.company.is_special_company) {
            if (data.company.name.toLowerCase() === "redcross") {
              return (
                <>
                  {OrderHelper.getCompany(data)}
                  <br /> <b>Afdeling:</b> {OrderHelper.getDept(data)}
                  <br /> <b>Enhed: </b> {OrderHelper.getDeptUnit(data)}(
                  {!_.isEmpty(data.department_unit) && (
                    <>
                      {data.department_unit.location_code} - {data.department_unit.location_code_2}
                    </>
                  )}
                  )
                  {data.attention_status === 1 && (
                    <>
                      <br />{" "}
                      <i
                        className="fa fa-exclamation-triangle"
                        style={{ color: "red", fontSize: "24px" }}
                      ></i>
                    </>
                  )}
                </>
              );
            } else {
              return (
                <>
                  {OrderHelper.getCompany(data)}
                  <br /> <b>Afdeling:</b> {OrderHelper.getDept(data)}
                  <br /> <b>Enhed: </b> {OrderHelper.getDeptUnit(data)}
                  {!_.isEmpty(data.department_unit) &&
                    !_.isEmpty(data.department_unit.ean_number) &&
                    _.isEmpty(data.ean_number) && (
                      <>
                        <br /> <b>EAN: </b> {data.department_unit.ean_number} <br />{" "}
                        <b>Economics Ref. ID: </b> {data.department_unit.economics_ref_id}{" "}
                      </>
                    )}
                  {!_.isEmpty(data.ean_number) && (
                    <>
                      <br /> <b>EAN: </b> {data.ean_number}
                    </>
                  )}
                  {data.attention_status === 1 && (
                    <>
                      <br />{" "}
                      <i
                        className="fa fa-exclamation-triangle"
                        style={{ color: "red", fontSize: "24px" }}
                      ></i>
                    </>
                  )}
                </>
              );
            }
          } else {
            if (!_.isEmpty(data.company)) {
              return data.company.name;
            } else {
              return "";
            }
          }
        }),
        this.records.current.createdTd("consumed_time", () => {
          if (!_.isEmpty(data.cancel)) {
            if (data.cancel.is_charge) {
              return (
                <>
                  <p>
                    <b> Hvordan: </b> {data.cancel.cancel_how}
                  </p>
                  <p>
                    <b> Hvornår: </b>
                    {data.cancel.cancel_when}
                  </p>
                  <p>
                    <b> Hvorfor: </b> {data.cancel.cancel_message}
                  </p>
                </>
              );
            }
          } else {
            if (
              data.translation_type_id == OrderType.DOCUMENT ||
              data.translation_type_id == OrderType.PHONE_MESSAGE
            ) {
              return "N/A";
            } else {
              return <TravelDistance order={data} from={"listing"} />;
            }
          }
        }),
        this.records.current.createdTd("preference", () => {
          return (
            <>
              {SpecificTranslatorNamecolor(data)}
              <br />
            </>
          );
        }),
        this.records.current.createdTd("translation_type", data.translation_type.title),

        this.records.current.createdTd("action", () => {
          return (
            <div>
              <span style={{ overflow: "visible", position: "relative", width: "110px" }}>
                <Link
                  to="#"
                  data-skin={"dark"}
                  title={Lang.view_order}
                  className={"btn btn-sm btn-clean btn-icon btn-icon-md"}
                  onClick={() => this.setState({ ModalData: data, show: true })}
                >
                  <i className={`kt-nav__link-icon ${Icons.view}`} />
                </Link>
              </span>
              <EditLink
                scope={"orders.update"}
                to={`/orders/order/edit/${data.id}`}
                data-skin={"dark"}
                title={Lang.edit_order}
              />
              <ActionLink
                scope={"orders.index"}
                to={"#"}
                onClick={() => OrderCommentService.showPopup(data)}
              >
                <i
                  className="la la-comments"
                  style={{ color: data.comments_count > 0 ? "red" : "" }}
                />{" "}
                <span
                  className="commentCountBox"
                  style={{ background: data.comments_count > 0 ? "red" : "" }}
                >
                  {data.comments_count}
                </span>
              </ActionLink>
              <ActionLink
                scope={"orders.index"}
                to={"#"}
                onClick={() =>
                  window.open(
                    `${base_url}api/admin/orders/orders/generate-pdf/${data.id}`,
                    "_newtab"
                  )
                }
                data-skin={"dark"}
                title={Lang.pdf}
              >
                <i className="la la-file-text-o" />
              </ActionLink>
              <ActionLink
                scope={"orders.copy-detail"}
                to={"#"}
                onClick={() => copyCodeToClipboard(data)}
                data-skin={"dark"}
                title={Lang.copy_details}
              >
                <i className="la la-copy" />
              </ActionLink>
              {data.translation_type_id === OrderType.DOCUMENT && (
                <Files files={data.order_files} />
              )}
              <CompleteOrderActions order={data} refresh={this.filterRecords} type={"order"} />
            </div>
          );
        }),
      ]
    );
    cols = _.compact(cols);

    return <tr key={list_key(data.id)}>{cols}</tr>;
  };

  filterRecords = (data?: any) => {
    if (this.records && this.records.current !== null) {
      if (data !== undefined) {
        this.records.current.setFormFields(data);
      } else {
        this.records.current.setFormFields();
      }
    }
  };

  show_distance = (order_id?: any) => {
    API.get(`orders/orders/get-travel-distance`, { params: { order_id: order_id } }).then((res) => {
      if (res.data.orderData != "") {
        this.setState({
          display_travel_time: res.data.orderData.travel_time,
          display_travel_distance: res.data.orderData.total_distance,
          display_order_id: order_id,
        } as any);
      } else {
        this.setState({
          display_travel_time: 0,
          display_travel_distance: 0,
          display_order_id: order_id,
        } as any);
      }
    });
  };

  change_paid_unpaid_status = (order_id?: any, action_type?: any) => {
    if (action_type === "unpaid") {
      Swal.fire({
        title: Lang.is_sure,
        text: Lang.unpaid_confirm,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
        cancelButtonText: Lang.cancel_text,
      }).then((result) => {
        if (result.value) {
          API.post("orders/orders/change-paid-unpaid-status", {
            order_id: order_id,
            is_paid: NewOrderStatus.UNPAID,
          }).then((response) => {
            showNotification("success", Lang.paid_unpaid_success);
            this.records.current.fetchData();
          });
        }
      });
    } else {
      API.post("orders/orders/change-paid-unpaid-status", {
        order_id: order_id,
        is_paid: NewOrderStatus.PAID,
      }).then((response) => {
        showNotification("success", Lang.paid_unpaid_success);
        this.records.current.fetchData();
      });
    }
  };

  render() {
    return (
      <ListingLayout breadcrumb={CompleteListBreadcrumb}>
        <ListHeader title={"complete_order_title"} icon={Icons.order}>
          {/* The Salary Caluclation export has a different endpoint passed as a param (url) */}
          <ExportButton
            records={this.records}
            fileName={Lang.export_salary_calculation}
            label={Lang.export_salary_calculation}
            precalculated={true}
          />
          &nbsp;&nbsp;&nbsp;
          <ExportButton
            records={this.records}
            fileName={Lang.export_task_overview}
            label={Lang.export_task_overview}
          />
          &nbsp;
        </ListHeader>
        <ListForm
          form={
            <CompleteOrderFilter
              languages={this.state !== null ? this.state.languages : []}
              companies={this.state !== null ? this.state.companies : []}
              getResults={this.filterRecords}
            />
          }
          table={
            <ShowRecords
              url={"orders/complete-orders"}
              showRecords={this.showContent}
              ref={this.records}
              isNeedAction={false}
              showCheckbox={false}
              actionButtons={actionButton({
                records: this.records,
                url: "orders/orders/bulk-action",
              })}
            />
          }
        />
        {this.state !== null && (
          <OrderInfo
            show={this.state.show}
            onHide={() => this.setState({ show: false })}
            orderinfo={this.state.ModalData}
          />
        )}
        <OrderComments />
        <HistoryPopup />
        <OrderFeedbackPopup />
        <OrderFeedbackEditPopup refresh={this.filterRecords} />
        <FixPrice />
        <Calllog />
        <Telmessage />
      </ListingLayout>
    );
  }
}
