// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".NextJob_container__mf1Nw, .NextJob_empty_container__2IYPf {\n  display: flex;\n  align-items: center;\n  border-radius: 20px;\n  width: 90%;\n}\n\n.NextJob_container__mf1Nw {\n  background-color: #245EE7;\n  color: white;\n  padding: 18px 32px;\n  border: none;\n  position: relative;\n  overflow: hidden;\n}\n\n.NextJob_empty_container__2IYPf {\n  background-color: transparent;\n  justify-content: center;\n  color: #245EE7;\n  border: 3px solid #245EE7;\n  height: 50%;\n  font-weight: 400;\n  font-size: 2rem;\n  opacity: 0.5;\n  transition: all 0.2s ease;\n  cursor: default;\n}\n.NextJob_empty_container__2IYPf:hover {\n  opacity: 1;\n  color: black;\n}\n\n.NextJob_logo__2nMwn {\n  width: 56px;\n  height: 56px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  position: relative;\n  background-color: white;\n  padding: 8px;\n  border-radius: 50%;\n}\n.NextJob_logo__2nMwn img {\n  height: 100%;\n  width: auto;\n  object-fit: cover;\n}\n\n.NextJob_company_details__2-t43 {\n  display: flex;\n  flex-direction: column;\n  margin-left: 2rem;\n  align-items: flex-start;\n}\n.NextJob_company_details__2-t43 h2 {\n  font-weight: 400;\n  font-size: 1.7rem;\n  margin: 0;\n}\n.NextJob_company_details__2-t43 span {\n  font-weight: 400;\n  opacity: 70%;\n  font-size: 1.2rem;\n}", ""]);
// Exports
exports.locals = {
	"container": "NextJob_container__mf1Nw",
	"empty_container": "NextJob_empty_container__2IYPf",
	"logo": "NextJob_logo__2nMwn",
	"company_details": "NextJob_company_details__2-t43"
};
module.exports = exports;
