import Lang from "../../common/lang/Lang";

export const urls = {
  home: "/systemInfo/",
  list: "/activity-logs",
}

export const ListBreadcrumb = [
  {
    label: Lang.system_info,
    url: urls.home
  },
  {
    label: Lang.activity_logs,
    url: "#"
  }
];