// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".FocusController_container__17uzs {\n  transition: all 0.2s ease;\n  cursor: unset;\n}\n\n.FocusController_expand__3gCZ- {\n  width: 20rem;\n}\n\n.FocusController_contract__2FgUc {\n  width: 10rem;\n}", ""]);
// Exports
exports.locals = {
	"container": "FocusController_container__17uzs",
	"expand": "FocusController_expand__3gCZ-",
	"contract": "FocusController_contract__2FgUc"
};
module.exports = exports;
