export interface CommonSettingModel {
  id: number;
  setting_name: string;
 setting_value: string;
  field_type: number;

}

export const CommonSettingValues = (data?: CommonSettingModel) => {
  return {
    setting_value: data !== undefined ? data.setting_value : '',
    setting_name: data !== undefined ? data.setting_name : '',
    field_type: data !== undefined ? data.field_type : 0,

  }
}