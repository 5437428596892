import React, {useState, useEffect} from 'react';
import Lang from "../../../common/lang/Lang";
import {QuizModel, QuizType, WeLearnModel} from "../../../model";
import {LearnService, QuizService} from "../../../services";
import {CommonSubmitButton, Select, showNotification} from "../../../common";
import {QuizValidations} from "../Validations";

export function EditQuiz(props: {id: number, title: string}) {
  const {id, title} = props;

  const [quizId] = useState(id);
  const [form, setForm] = useState({} as QuizModel);
  const [learn, setWeLearn] = useState([] as Array<WeLearnModel>);

  useEffect(() => {
    QuizService.view(quizId).then(res => {
      setForm({...res, o_image: res.image});
      if(res.quiz_type === QuizType.WE_LEARN){
        getLearnData();
      }
    })
  },[id]);



  function handleSubmit(e) {
    e.preventDefault();
    if($( "#quiz_form" ).valid()){
      QuizService.update(quizId, form).then(res => {
        showNotification('success', res);
      })
    }

  }

  function  handleChange  (event: React.ChangeEvent<HTMLElement>){
    const target : any = event.target;
    const value = target.type === 'file' ? target.files[0] :  target.value;
    const name = target.name;

    if(name === 'quiz_type' && value == QuizType.WE_LEARN){
      getLearnData();
    }

    setForm({...form,  [name]: value});
  }

  function getLearnData() {
    LearnService.we_learn_list().then(res => {
      setWeLearn(res);
    })
  }
  QuizValidations();
  return (
    <div className="kt-form">
      <div className="kt-wizard-v2__content" data-ktwizard-type="step-content" data-ktwizard-state="current">
        <div className="kt-heading kt-heading--md">{title}</div>
        <div className="kt-form__section kt-form__section--first">
          <form  id="quiz_form" onSubmit={handleSubmit}>
          <div className="kt-wizard-v2__form">
            <input type={'hidden'} id={'hidden_quiz_type'} defaultValue={`${form.quiz_type}`}/>
            { form.quiz_type !== QuizType.ONLINE_TEST &&
              <div className="form-group">
                <label className={'col-2 col-form-label'}> {Lang.quiz_type}<span className={'required'}>*</span></label>
                <div className="col-10">
                  <div className="kt-radio-inline">
                    <label className="kt-radio kt-radio--bold kt-radio--brand" style={{width: '30%'}}>
                      <input type={'radio'} onChange={handleChange} value={QuizType.ONLINE_QUIZ} name={'quiz_type'} checked={form.quiz_type == QuizType.ONLINE_QUIZ} className="form-control" />
                      {Lang.online_quiz}
                      <span/>
                    </label>
                    <label className="kt-radio kt-radio--bold kt-radio--brand" style={{width: '60%'}}>
                      <input type={'radio'} onChange={handleChange} value={QuizType.WE_LEARN} name={'quiz_type'} checked={form.quiz_type == QuizType.WE_LEARN} className="form-control" />
                      {Lang.is_quis_learn_p}
                      <span/>
                    </label>
                  </div>
                </div>
              </div>
            }
            {
              form.quiz_type == QuizType.WE_LEARN &&
              <div className="form-group">
                <label className={'col-form-label'}>{Lang.select_learn}<span className={'required'}>*</span></label>
                <Select className="form-control" name={'we_learn_id'} id={'we_learn_id'} value={form.we_learn_id} onChange={handleChange} >
                  <option value={''}>Select</option>
                  {learn.map((item, index) => {
                    return <option key={item.id} value={item.id}>{item.title}</option>
                  })}
                </Select>
              </div>
            }
            <div className="form-group">
              <label className={'col-form-label'}>{Lang.title} <span className={'required'}>*</span></label>
              <input type={'text'} onChange={handleChange} name={'title'} value={form.title} className="form-control" placeholder={Lang.enter_title}/>
            </div>

            <div className="form-group">
              <label className={'col-form-label'}>{Lang.description} <span className={'required'}>*</span></label>
              <textarea onChange={handleChange}  name={'description'}  value={form.description} className="form-control" placeholder={Lang.enter_description} />
            </div>
            <div className="form-group">
              <label className={'col-form-label'}>{Lang.image} </label>
              <div className="custom-file">
                <input type={'file'} onChange={handleChange}  name={'image'} className="custom-file-input"/>
                <label className="custom-file-label" htmlFor="customFile"/>
              </div>
              <br/>
              { form.o_image !== null && <img src={form.o_image} height={100} width={200} alt={'quiz image'}/> }

            </div>

            <div className="form-group">
              <label className={'col-form-label'}>{Lang.total_marks} <span className={'required'}>*</span></label>
              <input type={'text'}  onChange={handleChange}  name={'total_marks'}  value={form.total_marks} className="form-control" placeholder={Lang.placeholder_total_marks} />
            </div>

            <div className="form-group">
              <label className={'col-form-label'}>{Lang.min_marks} <span className={'required'}>*</span></label>
              <input type={'text'}  onChange={handleChange}  name={'min_marks'}  value={form.min_marks} className="form-control" placeholder={Lang.placeholder_min_marks} />
            </div>

            <div className="form-group">
              <label className={'col-form-label'}>{Lang.total_time}</label>
              <input type={'text'}  onChange={handleChange}  name={'time'}  value={form.time} className="form-control" placeholder={Lang.placeholder_total_time} />
            </div>


          </div>
          <div className="kt-form__actions">
            <div className="kt-font-bold kt-font-transform-u" data-ktwizard-type="action-next">
              <CommonSubmitButton/>
            </div>
          </div>
          </form>
        </div>
      </div>
    </div>
  )
}