
export interface TranslationTypeModel {
  id: number;
  title: string;
  created_at: string;
  status: number;
  icon: string;
  small_icon: string;
}

export const TransTypes = {
  TELEPHONE: 1,
  PHYSICAL: 2,
  VIDEO: 3,
  SIGN_LANGUAGE: 4,
  PHONE_MESSAGE: 5,
  DOCUMENT: 6,
};