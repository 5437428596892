// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Modal_overlay__15R2Y {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100vw;\n  height: 100vh;\n  background-color: rgba(0, 0, 0, 0.5);\n  z-index: 100;\n}\n\n.Modal_modal__ET8LZ {\n  position: fixed;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  background-color: #fff;\n  border-radius: 0.5rem;\n  box-shadow: 0 0 0.5rem 0.5rem rgba(0, 0, 0, 0.1);\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n}", ""]);
// Exports
exports.locals = {
	"overlay": "Modal_overlay__15R2Y",
	"modal": "Modal_modal__ET8LZ"
};
module.exports = exports;
