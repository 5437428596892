// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".LeaveDatePicker_date_container__32sZp {\n  display: flex;\n  align-items: center;\n  position: relative;\n  width: 100%;\n}\n\n.LeaveDatePicker_date_icon__2W9xe {\n  position: absolute;\n  left: 12px;\n  transform: translateY(-1px);\n}\n\n.LeaveDatePicker_date_input__2FrJx {\n  padding: 9px 1rem 9px 2.3rem;\n  border-radius: 3px;\n  border: 1px solid #d2d3d4;\n  width: 100%;\n}", ""]);
// Exports
exports.locals = {
	"date_container": "LeaveDatePicker_date_container__32sZp",
	"date_icon": "LeaveDatePicker_date_icon__2W9xe",
	"date_input": "LeaveDatePicker_date_input__2FrJx"
};
module.exports = exports;
