// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".TimePicker_time_picker_container__3NoDN {\n  display: flex;\n  align-items: center;\n  position: relative;\n}\n\n.TimePicker_time_picker_icon__tyeQl {\n  position: absolute;\n  left: 12px;\n  transform: translateY(-1px);\n}\n\n.TimePicker_time_picker_input__2zC1E {\n  padding: 5px 1rem 5px 2.3rem;\n  border-radius: 3px;\n  border: 1px solid #d2d3d4;\n  width: 100%;\n}\n.TimePicker_time_picker_input__2zC1E::placeholder {\n  color: #7A839C;\n  font-size: 10px;\n}", ""]);
// Exports
exports.locals = {
	"time_picker_container": "TimePicker_time_picker_container__3NoDN",
	"time_picker_icon": "TimePicker_time_picker_icon__tyeQl",
	"time_picker_input": "TimePicker_time_picker_input__2zC1E"
};
module.exports = exports;
