import * as React from 'react';
import ReactPaginate from "react-paginate";
import {SelectOptions} from "./form/SearchInput";

export interface PaginationMeta {
  current_page: number;
  from: number;
  last_page: number;
  path: string;
  per_page: number;
  to: number;
  total: number;
}

export const  PaginationMetaDefaultValue = {
  current_page: 0,
  from: 0,
  last_page: 0,
  path: "",
  per_page: 0,
  to: 0,
  total: 0
};


export interface PaginationSchema {
  totalPage: number;
  totalRecods: number;
  onPageChange: (selectedItem: { selected: number }) => void;
  marginPagesDisplayed?: number;
  pageRangeDisplayed?: number;
  recordLimit?: Array<SelectOptions>
  perPage: number,
  from: number,
  to: number,
  onLimitChange: (limit: number) => void;
  forcePage?: number;
}


export class Pagination extends React.Component<PaginationSchema> {
  static defaultProps = {
    marginPagesDisplayed: 2,
    pageRangeDisplayed:5,
    recordLimit:[{label: 10, value: 10}, {label: 20, value: 20},{label: 30, value: 30}, {label: 50, value: 50}, {label: 100, value: 100}]
  };

  constructor(props: PaginationSchema){
    super(props)

    this.state = {

    }
  }
  componentDidMount(){
    ($(".selectpicker") as any).selectpicker();
    const __this = this;
    $('#limit_holder').on('changed.bs.select', function (e, clickedIndex, isSelected, previousValue) {
      let limit = Number(__this.props.recordLimit[clickedIndex-1].value);
      __this.props.onLimitChange(limit)
    });
  }


  render(){
    return (
      <div className="kt-datatable__pager kt-datatable--paging-loaded">
        <ReactPaginate
          previousLabel={<i className="flaticon2-back"/>}
          previousLinkClassName={'kt-datatable__pager-link kt-datatable__pager-link--prev'}
          nextLabel={<i className="flaticon2-next"/>}
          nextLinkClassName={'kt-datatable__pager-link kt-datatable__pager-link--next'}
          pageCount={this.props.totalPage}
          onPageChange={this.props.onPageChange}
          marginPagesDisplayed={this.props.marginPagesDisplayed}
          pageRangeDisplayed={this.props.pageRangeDisplayed}
          containerClassName={'kt-datatable__pager-nav'}
          pageLinkClassName={'kt-datatable__pager-link kt-datatable__pager-link-number'}
          activeLinkClassName={'kt-datatable__pager-link--active'}
          forcePage={this.props.forcePage -1 }
        />

      </div>
    )
  }
}
