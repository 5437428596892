// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Statistics_container__NuO7E {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  width: 90%;\n}\n\n.Statistics_info_piece__14Ir0 {\n  color: black;\n  font-weight: 400;\n}\n.Statistics_info_piece__14Ir0 > span:nth-child(1) {\n  font-size: 4rem;\n  font-weight: 300;\n}", ""]);
// Exports
exports.locals = {
	"container": "Statistics_container__NuO7E",
	"info_piece": "Statistics_info_piece__14Ir0"
};
module.exports = exports;
