import React, {useReducer} from 'react';
import {QuestionType, QuizQuestionModel, QuizQuestionOptionModel} from "../../../model";
import _ from 'lodash';
import Lang from "../../../common/lang/Lang";

// all actions for assign translator
export enum ActionType {
  ADD_QUESTION_POPUP = 'ADD_QUESTION_POPUP',
  EDIT_QUESTION_POPUP = 'EDIT_QUESTION_POPUP',
  HIDE_POPUP = 'HIDE_POPUP',
  HANDLE_CHANGE = 'HANDLE_CHANGE',
  HANDLE_OPTION_CHANGE = 'HANDLE_OPTION_CHANGE',
  SUBMIT_FORM = 'SUBMIT_FORM',
  ADD_OPTIONS = 'ADD_OPTIONS',
  REMOVE_OPTIONS = 'REMOVE_OPTIONS',
}

export interface PopupState  {
  show: boolean;
  quiz_id: number;
  question_id: number;
  questions: Array<QuizQuestionModel>;
  form: QuizQuestionModel
}

export interface CAction {
  type: ActionType;
  payload?: PopupState
}

export const InitialState: PopupState = {
  show: false,
  quiz_id: 0,
  question_id: 0,
  questions: [],
  form: {question_type: QuestionType.OBJECTIVE, options: [{} as QuizQuestionOptionModel, {} as QuizQuestionOptionModel]} as QuizQuestionModel
};

// reducer for assign translator popup

export function QuestionReducer <PopupState, CAction > (state, action) {
  switch (action.type) {
    // show popup and set question data
    case ActionType.ADD_QUESTION_POPUP:
      return {...state, show: true, quiz_id: action.payload.quiz_id};

    // show popup and set question data
    case ActionType.EDIT_QUESTION_POPUP:
      return {...state, show: true, quiz_id: action.payload.quiz_id, question_id: action.payload.question.id, form: action.payload.question };

    // hide popup and blank old state
    case ActionType.HIDE_POPUP:
      return InitialState;

      // handle change data
    case ActionType.HANDLE_CHANGE:
      return {...state, form: {...state.form, ...action.payload}};

      // handle change data
    case ActionType.HANDLE_OPTION_CHANGE:
      let _options: any = [...state.form.options];
      if(action.payload.type === 'radio'){
        _options.map(item => {
          item.is_correct = false;
        })
      }
      _options[action.payload.dataset.id][action.payload.name] = action.payload.type === 'checkbox' ? action.payload.checked :  action.payload.value;
      return {...state, form: {...state.form, options: _options}};

    // add more orders
    case ActionType.ADD_OPTIONS:
      let options: Array<QuizQuestionOptionModel> = [...state.form.options];
      options.push({} as QuizQuestionOptionModel);
      return {...state, form: {...state.form, options: options}};

    // remove orders
    case ActionType.REMOVE_OPTIONS:
      let all__options: Array<QuizQuestionOptionModel> = [...state.form.options];
      _.pullAt(all__options, action.payload);
      return {...state, form: {...state.form, options: all__options}};


      // add new comment
    case ActionType.SUBMIT_FORM:
      return {...state, comments: [...state.comments, action.payload], form: {...state.form, comment: ''}};


    default:
      throw new Error();
  }
}

export function QuestionsValidations() {
  $(document).ready(function () {


    ($.validator as any).prototype.checkForm = function() {
      //overriden in a specific page
      this.prepareForm();
      for (let i = 0, elements = (this.currentElements = this.elements()); elements[i]; i++) {
        if (this.findByName(elements[i].name).length !== undefined && this.findByName(elements[i].name).length > 1) {
          for (let cnt = 0; cnt < this.findByName(elements[i].name).length; cnt++) {
            this.check(this.findByName(elements[i].name)[cnt]);
          }
        } else {
          this.check(elements[i]);
        }
      }
      return this.valid();
    };


    $( "#question_form" ).validate({
      rules: {
        question_type: "required",
        is_ques_con: {
          required: function () {
            return $("#is_ques_con").val() !== '1'
          }
        },
        total_marks: {
          required: true,
          number: true
        },
        is_correct: {
          required: true,
        },
        options: {
          required: true,
        },
      },
      errorPlacement: function(error, element) {
        element.addClass('is-invalid');
        if (element.attr("name") == "is_correct" ) {
          error.insertAfter(".is_correct_error:last").addClass('invalid-feedback');
        } else if(element.attr("name") == "is_ques_con" ) {
          error.insertAfter("#content-error").addClass('invalid-feedback');
        } else {
          error.insertAfter(element).addClass('invalid-feedback');
        }
      }

    });
  });
}