import * as React from 'react';
import { TableSchema} from "./Model";
import {TableHeader} from "./TableHeader";



export class Table extends React.Component <TableSchema>{

  render (){
    const {columns, data} = this.props;
    let start = this.props.start;
    return(
      <div className={"table-responsive"}>
        <table className={"table table-bordered table-striped"}>
          <thead className={'kt-datatable__head'}>
            <TableHeader columns={columns} showCheckbox={this.props.showCheckbox} checkAll={this.props.checkAll} onSort={this.props.onSort}/>
          </thead>
          <tbody>
            { data.map((item: any, index: number) => {
              return this.props.onShowContent(item, start++);
            })}
          </tbody>
        </table>
      </div>
    )
  }
}
