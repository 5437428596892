// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".JobsTable_company_column__3sXcN {\n  display: flex;\n  align-items: center;\n}\n.JobsTable_company_column__3sXcN .JobsTable_column_image__2IcCx {\n  width: 30px;\n  height: 30px;\n  display: grid;\n  place-content: center;\n  margin-right: 1rem;\n  padding: 4px;\n  border-radius: 50%;\n  background-color: white;\n}\n.JobsTable_company_column__3sXcN .JobsTable_column_image__2IcCx > img {\n  width: 100%;\n  height: 100%;\n  object-fit: cover;\n}\n\n.JobsTable_table_navigation__1qmSZ {\n  display: flex;\n  width: 100%;\n  align-items: center;\n  justify-content: flex-end;\n  font-size: 1.4rem;\n  margin-bottom: 0.5rem;\n  font-weight: 300;\n}\n\n.JobsTable_page_number__1A-yB {\n  margin: 0 1rem;\n  font-weight: 500;\n  cursor: pointer;\n  transition: color 0.2s ease;\n}\n.JobsTable_page_number__1A-yB:hover {\n  color: #245EE7;\n}\n\n.JobsTable_table_nav_buttons__3-R7I {\n  background-color: transparent;\n  padding: 2px 10px;\n  border: none;\n  transition: color 0.2s ease;\n}\n.JobsTable_table_nav_buttons__3-R7I:hover {\n  color: #245EE7;\n}", ""]);
// Exports
exports.locals = {
	"company_column": "JobsTable_company_column__3sXcN",
	"column_image": "JobsTable_column_image__2IcCx",
	"table_navigation": "JobsTable_table_navigation__1qmSZ",
	"page_number": "JobsTable_page_number__1A-yB",
	"table_nav_buttons": "JobsTable_table_nav_buttons__3-R7I"
};
module.exports = exports;
