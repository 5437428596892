// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".CurrentJob_container__3sDll, .CurrentJob_empty_container__cI-Yr {\n  border-radius: 20px;\n  display: grid;\n  width: 90%;\n}\n\n.CurrentJob_container__3sDll {\n  background-color: #03C3AB;\n  color: black;\n  padding: 14px 37px 24px;\n  align-items: center;\n  grid-template-columns: 1fr 3fr;\n  grid-template-rows: 3fr 2fr;\n  grid-template-areas: \"image details\" \"info info\";\n}\n\n.CurrentJob_empty_container__cI-Yr {\n  background-color: transparent;\n  color: #03C3AB;\n  place-content: center;\n  border: 3px solid #03C3AB;\n  font-weight: 400;\n  height: 80%;\n  font-size: 2rem;\n  opacity: 0.7;\n  transition: all 0.2s ease;\n  cursor: default;\n}\n.CurrentJob_empty_container__cI-Yr:hover {\n  opacity: 1;\n  color: black;\n}\n\n.CurrentJob_company_image__2FbKp {\n  width: 80px;\n  height: 80px;\n  display: grid;\n  place-content: center;\n  background-color: white;\n  border-radius: 50%;\n  padding: 15px;\n}\n.CurrentJob_company_image__2FbKp > img {\n  width: 100%;\n  height: auto;\n  object-fit: cover;\n}\n\n.CurrentJob_company_details__2k9Cv {\n  display: flex;\n  flex-direction: column;\n  opacity: 0.7;\n  font-size: 1.1rem;\n  font-weight: 400;\n}\n.CurrentJob_company_details__2k9Cv .CurrentJob_title__1nAUh {\n  opacity: 1;\n  font-size: 2.4rem;\n}\n.CurrentJob_company_details__2k9Cv .CurrentJob_icon__lTF7F {\n  margin-right: 0.8rem;\n}\n\n.CurrentJob_info__2L0xb {\n  grid-area: info;\n  padding-top: 1.5rem;\n  width: 100%;\n  display: flex;\n  padding: 1.5rem 10% 0 0;\n  display: flex;\n  justify-content: space-between;\n  position: relative;\n}\n.CurrentJob_info__2L0xb h5 {\n  margin: 0;\n  font-weight: 400;\n  opacity: 70%;\n  position: absolute;\n}\n.CurrentJob_info__2L0xb .CurrentJob_info_highlight__rBE77 {\n  display: block;\n  font-weight: 300;\n  font-size: 2.5rem;\n  margin-top: 1.5rem;\n}", ""]);
// Exports
exports.locals = {
	"container": "CurrentJob_container__3sDll",
	"empty_container": "CurrentJob_empty_container__cI-Yr",
	"company_image": "CurrentJob_company_image__2FbKp",
	"company_details": "CurrentJob_company_details__2k9Cv",
	"title": "CurrentJob_title__1nAUh",
	"icon": "CurrentJob_icon__lTF7F",
	"info": "CurrentJob_info__2L0xb",
	"info_highlight": "CurrentJob_info_highlight__rBE77"
};
module.exports = exports;
