import * as React from 'react';
import {CreateBreadcrumb, IState} from "./Data";
import {RouteComponentProps} from "react-router";
import {QuizType} from "../../model";
import {CreateLayout} from "../../layout/CreateLayout";
import {QuizForm} from "../quiz-form/Quiz";

export class OnlineTestCreate extends React.Component<RouteComponentProps, IState> {

  componentDidMount() {
    document.title = "Create Online Test";
  }

  render() {
    return (
        <CreateLayout breadcrumb={CreateBreadcrumb} title={'add_we_learn'}>
          <QuizForm from={QuizType.ONLINE_TEST} {...this.props}/>
        </CreateLayout>
    )
  }
}