import * as React from "react";
import {MouseEventHandler} from "react";

export interface ButtonSchema {
  label: string;
  class? : string;
  className? : string;
  onClick? : MouseEventHandler;
  type?: "button"| "submit"| "reset";
  loading?: boolean
}

export interface BulkActionButtonSchema extends ButtonSchema {
  html? : string;
  show: boolean
}

export const Button = (props: ButtonSchema) =>  {
  return (
    <button
      type={props.type !== undefined ? props.type : "button"}
      className={props.class !== undefined ? props.class : "btn btn-sm btn-default"}
      onClick={props.onClick}
    >
      {props.label}
    </button>
  );
}


export const LoadingButton = (props: ButtonSchema) => {
  if(props.loading === true){
    return (
      <button
        type={props.type !== undefined ? props.type : "button"}
        className={props.className !== undefined ? props.className : "btn btn-sm btn-default"}
      >
        {props.label} &nbsp; <i className={'fa fa-spinner'}/>
      </button>
    );
  } else {
    return (
      <button
        type={props.type !== undefined ? props.type : "button"}
        className={props.className !== undefined ? props.className : "btn btn-sm btn-default"}
        onClick={props.onClick}
      >
        {props.label}
      </button>
    );
  }
}
